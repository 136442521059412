import './Landing.css'
import { StravaAPI } from '../../Helpers/StravaAPIHelper'
import forecastmockup from '../../Assets/Hourly_Forecast.png'
import logo from './../../Assets/homepage.png'
import liveconditions from './../../Assets/LiveConditions.png'
import stravacaption from './../../Assets/caption.png'
import effortanalysis from '../../Assets/effortanalysis.png'
import { Analytics } from '../../Helpers/Analytics'
import { useEffect } from 'react'
import Race from '../../Assets/LandingRace.png'
import highContrastMap from '../../Assets/highcontrast-map.jpg'
import highContrastForecast from '../../Assets/highcontrast-forecast.jpg'
import highContrastRace from '../../Assets/highcontrast-race.jpg'
import highContrastEfforts from '../../Assets/highcontrast-efforts.png'


export function Landing({cookies, setCookie}) {

  useEffect(() => { Analytics.logPageView("Landing Page", "/", cookies, setCookie) }, [])

  function isNotLoggedIn() { return (cookies.user === undefined || cookies.user === null || Object.keys(cookies.user).length === 0 || cookies.user.athlete === null) }

  function startUsing() {
    if (isNotLoggedIn()) {
      StravaAPI.authenticate()
    } else {
      window.location.href = '/segments'
    }
  }

  function LandingSection({side, title, image, description, callToAction, link, isPlugin=false, sample=false}) {
    if (side === 'left') {
      return (
        <div className='LandingContent2 LeftText'>
          <div className='ImageExplanationLeft'>
            <h2>{title}</h2>
            <p className='DesktopOnly'>{description}</p>
            <div className="GoToPageButton DesktopOnly" onClick={() => { window.location.href = link }}>{callToAction}</div>
          </div>
          <img className={isPlugin ? 'SmallPluginMockup' : ''} src={image}/>
          <p className='MobileOnly'>{description}</p>
          <div className="GoToPageButton MobileOnly" onClick={() => { window.location.href = link }}>{callToAction}</div>
        </div>
      )
    } else {
      return (
        <div className='LandingContent2 RightText'>
          <h2 className='MobileOnly'>{title}</h2>
          <img className={isPlugin ? 'SmallPluginMockup' : ''} src={image}/>
          <div className='ImageExplanationRight'>
              <h2 className='DesktopOnly'>{title}</h2>
              <p>{description}</p>
              { sample ? <a style={{color: "var(--navyblue)", lineHeight: "2", opacity: 0.9, textDecoration: "underline", display: "table-cell"}} target="_blank" href='https://windmate.io/activity?id=11441040440'>See an example ></a> : <div style={{display: "none"}}/>}
              <div className="GoToPageButton" style={{marginTop: "15px"}} onClick={() => { window.location.href = link }}>{callToAction}</div>
          </div>
        </div>
      )
    }
  }

  return (
    <div className=''>
        <div>

        <div className='TitleContent'>
          <div className='LogoImage'>
            <img src={logo}/>
          </div>
          <div className="TitleText">
              <h1>Weather forecasts and insights for segments.</h1>
              <div className='StartUsing DesktopOnly' onClick={startUsing}>Get Started using WindMate</div>
          </div>
        </div>
        <div className='StartUsing MobileOnly' onClick={startUsing}>Get Started using WindMate</div>

          <div className='LandingContent2 RightText'>
            <h2 className='MobileOnly'>Hourly conditions, visualized beautifully</h2>
            <img src={cookies.highContrast === true ?  highContrastForecast : forecastmockup}/>
            <div className='ImageExplanationRight'>
              <h2 className='DesktopOnly'>Hourly conditions, visualized beautifully</h2>
              <p>WindMate uses the most reliable wind data from <b>tomorrow.io</b> to compute the weather impact on any segment, giving you increased insight into the conditions that await your effort.</p>
              <div className="GoToPageButton" onClick={() => { window.location.href = '/segments' }}>View Forecasted Conditions</div>
            </div>
          </div>

          <div className='LandingContent2 LeftText'>
            <div className='ImageExplanationLeft'>
              <h2>Live conditions mapped along the segment</h2>
              <p className='DesktopOnly'>Visualize current wind conditions drawnn along the segment trajectory. See which parts of the segment correspond to a headwind, crosswind, or the beloved tailwind.</p>
              <div className="GoToPageButton DesktopOnly" onClick={() => { window.location.href = '/segments' }}>View Segments</div>
            </div>
            <img src={cookies.highContrast === true ?  highContrastMap : liveconditions}/>
            <p className='MobileOnly'>Visualize current wind conditions drawn along the segment trajectory. See which parts of the segment correspond to a headwind, crosswind, or the beloved tailwind.</p>
            <div className="GoToPageButton MobileOnly" onClick={() => { window.location.href = '/segments' }}>View Segments</div>
          </div>

          <div className='LandingContent2 RightText'>
            <h2 className='MobileOnly'>PR & past effort weather conditions</h2>
            <img src={cookies.highContrast === true ?  highContrastEfforts : effortanalysis}/>
            <div className='ImageExplanationRight'>
              <h2 className='DesktopOnly'>PR & past effort weather conditions</h2>
              <p>See the wind conditions for your previous segment attempts, so you can better understand how weather impacted your performance.</p>
              <div className="GoToPageButton" onClick={() => { window.location.href = '/segments' }}>View past efforts</div>
            </div>
          </div>

          <div className='LandingContent2 LeftText'>
            <div className='ImageExplanationLeft'>
              <h2>Plan Race Day Tactics</h2>
              <p className='DesktopOnly'> We've all been there - you finally launch your attack off the front only to be met with the world's most aggressive headwind. With WindMate visualize exactly which parts of the course have a tailwind to maximize that race winning move. Go get 'em champ!</p>
              <div className="GoToPageButton DesktopOnly" onClick={() => { window.location.href = '/segments' }}>Browse Courses</div>
            </div>
            <img className='SmallPluginMockup' src={cookies.highContrast === true ?  highContrastRace : Race}/>
            <p className='MobileOnly'>We've all been there - you finally launch your attack off the front only to be met with the world's most aggressive headwind. With WindMate visualize exactly which parts of the course have a tailwind to maximize that race winning move. Go get 'em champ!</p>
            <div className="GoToPageButton MobileOnly" onClick={() => { window.location.href = '/segments' }}>Browse Courses</div>
          </div>

          <LandingSection side="right" image={stravacaption} isPlugin={true} title="Automatic Strava captions" description="The WindMate Strava plugin automatically analyzes your ride and gives you a detailed breakdown of how wind conditions affected your efforts on favorited segments. Give yourself some credit, we're sure the headwind was tough!" callToAction="Enable Strava Plug-in" link="/plugin" sample={true}/>

        </div>
    </div>
  )
}