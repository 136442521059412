export function formatCoordinates(coords) {

    let [minX, maxX, minY, maxY] = [1000, -1000, 1000, -1000]
    for (const [x, y] of coords) { minX = Math.min(minX, x); maxX = Math.max(maxX, x); minY = Math.min(minY, y); maxY = Math.max(maxY, y) }
    const maxSizeDiff = Math.max(maxX - minX, maxY - minY)
    const padding = maxSizeDiff * 0.1;

    return {
        coords: coords,
        bounds: [[minX - padding, minY - padding], [maxX + padding, maxY + padding]]
    }
}