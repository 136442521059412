import { Firestore } from "../firebase";
import { Analytics } from "./Analytics";
import axios from "axios";


// Functions to interact with the Strava API
export class StravaAPI {

    // Takes users to Strava's authentication portal
    static authenticate(force=false, state='') {
        window.location.href = `https://www.strava.com/oauth/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&scope=read,activity:write,activity:read_all,profile:read_all&approval_prompt=${force === true ? 'force' : 'auto'}&state=${state}`;
    }

    static async getTokensFromCode(code, allowedScopes) {
        try {
            const response = await axios.get('https://us-central1-mygame-c2230.cloudfunctions.net/getAccessToken', { params: { 
                code: code, 
                scopes: allowedScopes 
            }})
            if (response.data.failed === true) { console.log('Failed to get tokens.'); return null }
            return response.data;
        } catch (err) { console.log(err); return null }
    }

    static async getNewTokens(athleteID, athleteSecret) {
        try {
            const response = await axios.get('https://us-central1-mygame-c2230.cloudfunctions.net/getAccessToken', { params: { 
                athlete_id: athleteID,
                secret: athleteSecret
            }})
            if (response.data.failed === true) { console.log('Failed to refresh tokens.'); return null }
            return response.data;
        } catch (err) { console.log(err); return null }
    }

    // Returns a list of the currently logged in user's starred segments
    static async getStarredSegments(accessToken) {
        try {
            const response = await axios.get(`https://www.strava.com/api/v3/segments/starred`, { params: {
                per_page: 200
            }, headers: { Authorization: `Bearer ${accessToken}` }})
            return response.data.filter((segment) => segment.activity_type === 'Ride' || segment.activity_type === 'Run' || segment.activity_type === 'Hike').sort((a,b) => (a.name > b.name) ? 1 : -1)
        } catch (err) { 
            Analytics.logStravaAPIError("get_starred_segments_failed")
            console.log(err); 
            return null 
        }
    }

    // Returns a list of the user's routes
    static async getRoutes(athleteID, accessToken) {
        try {
            const response = await axios.get(`https://www.strava.com/api/v3/athletes/${athleteID}/routes`, { params: {
                per_page: 200
            }, headers: { Authorization: `Bearer ${accessToken}` }})
            return response.data.sort((a,b) => (a.name > b.name) ? 1 : -1)
        } catch (err) { 
            Analytics.logStravaAPIError("get_routes_failed")
            console.log(err); 
            return null 
        }
    }

    // Returns data about a segment
    static async getSegment(segmentID, accessToken) {
        try {
            const response = await axios.get(`https://www.strava.com/api/v3/segments/${segmentID}`, { 
                headers: { Authorization: `Bearer ${accessToken}` }})
            return response.data
        } catch (err) { 
            Analytics.logStravaAPIError("get_segment_info_failed")
            console.log(err); 
            return null ;
        }
    }

    // Retuns data for a specific route
    static async getRoute(routeID, accessToken) {
        try {
            const response = await axios.get(`https://www.strava.com/api/v3/routes/${routeID}`, { 
                headers: { Authorization: `Bearer ${accessToken}` }})
            return response.data
        } catch (err) { 
            Analytics.logStravaAPIError("get_segment_info_failed")
            console.log(err); 
            return null ;
        }
    }

    // Returns a list of segment efforts
    static async getSegmentEfforts(segmentID, accessToken) {
        try {
            const response = await axios.get('https://www.strava.com/api/v3/segment_efforts?', { params: {
                    segment_id: segmentID,
                    per_page: 200
                }, headers: { Authorization: `Bearer ${accessToken}` }});
            return response.data.sort((a, b) => new Date(b.start_date_local) - new Date(a.start_date_local))
        } catch (err) { 
            Analytics.logStravaAPIError("get_segment_efforts_failed")
            console.log(err); 
            return null 
        }
    }

    // Get info about the currently signed in user
    static async getUserInfo(accessToken) {
        try {
            const response = await axios.get("https://www.strava.com/api/v3/athlete", { headers: { Authorization: `Bearer ${accessToken}` }})
            return response.data
        } catch (err) { 
            Analytics.logStravaAPIError("get_user_info_failed")
            console.log(err); 
            return null 
        }
    }

    // Returns a list of coordinate pairs
    static async getSegmentCoordinates(segmentID, accessToken) {
        try {
            const response = await axios.get(`https://www.strava.com/api/v3/segments/${segmentID}/streams`, { params: {
                keys: "latlng",
                key_by_type: "True"
            }, headers: { Authorization: `Bearer ${accessToken}` }})
            return response.data.latlng.data
        } catch (err) { 
            Analytics.logStravaAPIError("get_segment_coordinates_failed")
            console.log(err); 
            return null 
        }
    }

}


export async function refreshTokensIfNeeded(cookies, setCookie) {

    function validAccessToken() {
        const expiresAt = cookies.user.expiresAt;
        const currentTime = Math.floor(Date.now() / 1000);
        return (expiresAt > (currentTime + 60)); // must be valid for at least 1 more minute
    }

    function saveTokensToCookies(tokens) {
        const updatedUser = {
            ...cookies['user'],
            accessToken: tokens.accessToken,
            expiresAt: tokens.expiresAt
        };

        setCookie('user', updatedUser, { path: '/' });
    }

    if (validAccessToken() === true) {
        console.log('Access token is valid')
        return cookies.user.accessToken
    } else {
        console.log('Getting new access token...')
        const tokens = await StravaAPI.getNewTokens(cookies.user.athlete.id, cookies.user.secret);
        console.log('Saving new token to cookies')
        saveTokensToCookies(tokens);
        return tokens.accessToken
    }
}

