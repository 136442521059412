// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, doc, setDoc, getDoc, updateDoc, collection, getDocs } from "firebase/firestore";
import { Analytics } from "./Helpers/Analytics";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDtYMi45udBI9a0agmTCd0ILk7Ow-TKuEo",
  authDomain: "mygame-c2230.firebaseapp.com",
  databaseURL: "https://mygame-c2230.firebaseio.com",
  projectId: "mygame-c2230",
  storageBucket: "mygame-c2230.appspot.com",
  messagingSenderId: "989946244645",
  appId: "1:989946244645:web:d5ee38337132e757ee99cc",
  measurementId: "G-N6B4QH6V0Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export async function saveSegmentCoordsToDB(segmentID, coords) {
  try { 
    await setDoc(doc(db, "segment_coordinates", `${segmentID}`), {
      segment_id: segmentID,
      coordinates: coords.join(' ')
    });
    console.log('Saved!')
  } catch (err) { console.log(err) }
}

export class Firestore {

  static async getSubscriptionStatus(athleteID) {
    const ref = doc(db, 'subscription', 'users_enabled')
    try {
      const d = await getDoc(ref);
      return (d.exists() && d.data()[`${athleteID}`] === true);
    } catch (err) { console.log(err) }
    return false;
  }

  static async getPluginStatus(athleteID) {
    const ref = doc(db, "plugin", "users_enabled");
    
    try {
      const d = await getDoc(ref);
      return (d.exists() && d.data()[athleteID] === true);
    } catch (err) { console.log(err); return false }
  }

  static async setPluginStatus(athleteID, newStatus) {
    const ref = doc(db, "plugin", "users_enabled");
    let update = {}
    update[athleteID] = newStatus

    if (newStatus) { Analytics.logKeyEvent('plugin_enabled') } else { Analytics.logKeyEvent('plugin_enabled') }

    try {
      await updateDoc(ref, update);
    } catch (err) { console.log(err) }
  }

  static async getSegmentCoordinates(segmentID) {
    const segmentRef = doc(db, "segment_coordinates", `${segmentID}`);

    try {
      const segmentSnap = await getDoc(segmentRef);
      if (segmentSnap.exists()) {
        return segmentSnap.data().coordinates.split(' ').map(c => c.split(','));
      } else { return null; }
    } catch (err) { console.log(err); return null; }
  }

  static async getEffortConditions(effortID) {
    const ref = doc(db, 'effort_wind_data', `${effortID}`)
    try {
      const d = await getDoc(ref)
      if (d.exists()) { return d.data() }
    } catch (err) { console.log(err) }
    return null
  }

  static async getStoredActivtyInfo(activityID) {
    const ref = doc(db, 'activities', `${activityID}`)
    try {
      const d = await getDoc(ref)
      if (d.exists()) { return d.data() }
    } catch (err) { console.log(err) }
    return null
  }

  static async getActivityEfforts(activityID) {
    const ref = collection(db, 'activities', `${activityID}`, 'efforts')
    try {
      const docs = await getDocs(ref)
      const efforts = {}
      docs.forEach((doc) => { const effort = doc.data(); efforts[effort.effort_id] = effort })
      return efforts
    } catch (err) { console.log(err) }
    return null
  }

}