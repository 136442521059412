import Chart from 'chart.js/auto'

export function formatDate(inputDate) {
  const date = new Date(inputDate);
  const hours = date.getHours();
  
  const formattedHours = hours % 12 || 12; // 0 should be displayed as 12
  const period = hours < 12 ? 'am' : 'pm';
  
  return`${formattedHours}${period}`
}

export async function drawPressureChart(weatherData, chart, setChart, n) {
  if (document.getElementById('airpressure') === null) { return }

  const style = getComputedStyle(document.body)
  const pressureOpaque = style.getPropertyValue('--pressureOpaque')
  const pressureTransparent = style.getPropertyValue('--pressureTransparent')

  const timestamps = Object.keys(weatherData).slice(0, n)
  let pressures = [];

  for (const timestamp of timestamps) {
    const timestampData = weatherData[timestamp];
    pressures.push((timestampData.pressureSurfaceLevel * 33.8638).toFixed(2))
  }

  function formattedData() {
    return {
      labels: timestamps.map(l => formatDate(l)),
      datasets: [{
        label: 'Air Pressure',
        data: pressures,
        fill: {
          target: 'origin',
          below: pressureTransparent,
          above: pressureTransparent,
        }
      }]
    }
  }

  if (chart !== null) { chart.destroy(); setChart(null) }; 

  const config = {
    type: 'line',
    data: formattedData(),
    options: {
      plugins: { legend: { display: false } },
      maintainAspectRatio: false,
      interaction: { intersect: false, mode: 'index' },
      scales: {
        x: { grid: { display:false }, ticks: { display: true, font:{
          weight: 'bold',
        } }},
        y: { grid: { display:false }, ticks: { display: true }},
      },
      backgroundColor: "white",
      borderColor: pressureOpaque,
    }
  };

  setChart(new Chart(document.getElementById('airpressure'), config));
}

export async function drawWindChart(weatherData, chart, setChart, n, preferredUnits) {
  if (document.getElementById('windspeed') === null) { return }

  const style = getComputedStyle(document.body)
  const windSpeedOpaque = style.getPropertyValue('--windSpeedOpaque')
  const windSpeedTransparent = style.getPropertyValue('--windSpeedTransparent')

  const timestamps = Object.keys(weatherData).slice(0, n)
  let windspeeds = [];

  for (const timestamp of timestamps) {
    const timestampData = weatherData[timestamp];
    if (preferredUnits === 'meters') {
      windspeeds.push((timestampData.windSpeed * 1.60934).toFixed(1))
    } else { windspeeds.push(timestampData.windSpeed.toFixed(1)) }
  }

  function formattedData() {
    return {
      labels: timestamps.map(l => formatDate(l)),
      datasets: [{
        label: 'Wind Speed',
        data: windspeeds,
        fill: {
          target: 'origin',
          below: windSpeedTransparent,
          above: windSpeedTransparent,
        }
      }]
    }
  }

  if (chart !== null) { chart.destroy(); setChart(null) }; 

  const config = {
    type: 'line',
    data: formattedData(),
    options: {
      plugins: { legend: { display: false } },
      maintainAspectRatio: false,
      interaction: { intersect: false, mode: 'index' },
      scales: {
        x: { grid: { display:false }, ticks: { display: true, font:{ weight: 'bold', }}},
        y: { grid: { display:false }, ticks: { display: true }},
      },
      backgroundColor: "white",
      borderColor: windSpeedOpaque
    }
  };

  setChart(new Chart(document.getElementById('windspeed'), config));
}

export async function drawTempChart(weatherData, chart, setChart, n, preferredUnits) {
  
  const style = getComputedStyle(document.body)
  const temperatureOpaque = style.getPropertyValue('--temperatureOpaque')
  const temperatureTransparent = style.getPropertyValue('--temperatureTransparent')

  if (document.getElementById('temperature') === null) { return }

  const timestamps = Object.keys(weatherData).slice(0, n)
  let temps = [];

  for (const timestamp of timestamps) {
    const timestampData = weatherData[timestamp];
    
    if (preferredUnits === 'meters') {
      temps.push(((timestampData.temperature - 32) * (5/9) ).toFixed(1))
    } else { temps.push(timestampData.temperature.toFixed(1)) }
  }

  function formattedData() {

    return {
      labels: timestamps.map(l => formatDate(l)),
      datasets: [{
        label: 'Temperature',
        data: temps,
        fill: {
          target: 'origin',
          below: temperatureTransparent,
          above: temperatureTransparent,
        }
      }]
    }
  }

  if (chart !== null) { chart.destroy(); setChart(null) }; 

  const config = {
    type: 'line',
    data: formattedData(),
    options: {
      plugins: { legend: { display: false } },
      maintainAspectRatio: false,
      interaction: { intersect: false, mode: 'index' },
      scales: {
        x: { grid: { display:false }, ticks: { display: true, font:{ weight: 'bold', }}},
        y: { grid: { display:false }, ticks: { display: true }},
      },
      backgroundColor: "white",
      borderColor: temperatureOpaque,
    }
  };

  setChart(new Chart(document.getElementById('temperature'), config));
}

export async function drawForecast(windData, chart, setChart, n, setSelectedTimeIndex) {

  const style = getComputedStyle(document.body)
  const green = style.getPropertyValue('--windGreen')
  const yellow = style.getPropertyValue('--windYellow')
  const red = style.getPropertyValue('--windRed')

  const timestamps = Object.keys(windData).slice(0, n)
  let [headwind, tailwind, crosswind] = [[], [], []];

  for (const timestamp of timestamps) {
    const timestampData = windData[timestamp];
    headwind.push((timestampData.headwind * 100).toFixed(1))
    tailwind.push((timestampData.tailwind * 100).toFixed(1))
    crosswind.push(((1 - timestampData.tailwind - timestampData.headwind) * 100).toFixed(1))
  }

  function formattedData() {

    return {
      labels: timestamps.map(l => formatDate(l)),
      datasets: [
        {
          label: 'Headwind',
          data: headwind,
          backgroundColor: red,
          borderColor: red,
        },
        {
          label: 'Crosswind',
          data: crosswind,
          backgroundColor: yellow,
          borderColor: yellow,
        },
        {
          label: 'Tailwind',
          data: tailwind,
          backgroundColor: green,
          borderColor: green,
        }
      ]
    }
  }

  function detectHover(evt, item) {
    if (Object.values(item).length == 0) { setSelectedTimeIndex(null) }
    else ( setSelectedTimeIndex(item[0].index) )
  }


  if (chart !== null) { chart.destroy(); setChart(null) }; 

  const config = {
    type: 'bar',
    data: formattedData(),
    options: {
        onHover: detectHover,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
            x: {
              grid: {
                display:false
              },
              ticks: {
                font:{
                  weight: 'bold',
                }
              },
              
              stacked: true,
              
            },
            y: {
              display: false,
              grid: { display: false},
              stacked: true,
              ticks: {
                display: false
              }
            }
        },
        plugins: {
          legend: {
            labels: {useBorderRadius: true, borderRadius: 6, boxWidth: 50}
          },
          tooltip: {
            callbacks: {
              // footer: footer,
              label: function(context) {
                let label = context.dataset.label || '';

                if (label) {
                    label += ': ';
                }
                if (context.parsed.y !== null) {
                    label += `${context.parsed.y}%`;
                }
                return label;
              }
            }
          }
        }
    }
  };

  setChart(new Chart(document.getElementById('forecast'), config));
};