import Navbar from './Global/Navbar/Navbar'
import Footer from './Global/Footer/Footer';
import { Landing } from './Pages/Landing/Landing';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CookiesProvider, useCookies } from 'react-cookie'
import { Segments } from './Pages/Home/Segments';
import { Segment } from './Pages/Segment/Segment';
import Plugin from './Pages/Plugin/Plugin';
import { Authenticated } from './Pages/Authenticated/SignedIn';
import { About } from './Pages/About/About';
import { Subscribe } from './Pages/Subscribe/Subscribe';
import { useEffect, useState } from 'react';
import { PageNotFound } from './Components/PageNotFound';
import { SubscribeSuccess } from './Components/SubscribeSuccess';
import { Activity } from './Pages/Activity/Activity';
import { RoutesPage } from './Pages/Routes';
import { RoutePage } from './Pages/Route';


import ReactGA from 'react-ga4';
const TRACKING_ID = "G-439709559";


function App() {

  const [cookies, setCookie] = useCookies(['user', 'starred_segments'])
  const [allSegments, setAllSegments] = useState([])
  const [maps, setMaps] = useState(null)
  const r = document.querySelector(':root');

  useEffect(() => { ReactGA.initialize(TRACKING_ID) }, [])
  useEffect(() => { setColors() }, [cookies.highContrast])

  function setColors() {
    if (cookies.highContrast === true) {
      r.style.setProperty('--windRed', '#D81B60');
      r.style.setProperty('--windGreen', '#004D40');
      r.style.setProperty('--windYellow', '#FFC107');
    } else {
      r.style.setProperty('--windRed', '#DD5746');
      r.style.setProperty('--windGreen', '#AFD198');
      r.style.setProperty('--windYellow', '#FFC470');
    }
  
    // --temperatureOpaque: rgba(220, 134, 134, 1);
    // --temperatureTransparent: rgba(220, 134, 134, 0.5);
  
    // --pressureOpaque: rgba(129, 104, 157, 1);
    // --pressureTransparent: rgba(129, 104, 157, 0.5);
  
    // --windSpeedOpaque: rgba(155, 184, 205, 1);
    // --windSpeedTransparent: rgba(155, 184, 205, 0.5);
  }

  return (
      <CookiesProvider>
        <Navbar cookies={cookies} setCookie={setCookie}/>
          <Router>
            <Routes>
              <Route path="/" element={<Landing cookies={cookies} setCookie={setCookie}/>}/>
              <Route path="/authenticated" element={<Authenticated cookies={cookies} setCookie={setCookie}/>}/>
              <Route path="/segment" element={<Segment cookies={cookies} setCookie={setCookie}/>}/>
              <Route path="/activity" element={<Activity cookies={cookies} setCookie={setCookie}/>}/>
              <Route path="/plugin" element={<Plugin cookies={cookies} setCookie={setCookie}/>}/>
              <Route path="/about" element={<About cookies={cookies} setCookie={setCookie}/>}/>
              <Route path="/subscribe" element={<Subscribe cookies={cookies} setCookie={setCookie}/>}/>
              <Route path="/segments" element={<Segments cookies={cookies} setCookie={setCookie} allSegments={allSegments} setAllSegments={setAllSegments} maps={maps} setMaps={setMaps}/>}/>
              <Route path="/mobile/authenticated" element={<MobileAuth/>}/>
              <Route path="/success" element={<SubscribeSuccess/>}/>
              <Route path="/routes" element={<RoutesPage cookies={cookies} setCookie={setCookie}/>}/>
              <Route path="/route" element={<RoutePage cookies={cookies} setCookie={setCookie}/>}/>
              <Route path="*" element={<PageNotFound cookies={cookies} setCookie={setCookie}/>}/>
            </Routes>
          </Router>
        <Footer cookies={cookies} setCookie={setCookie}/>
      </CookiesProvider>
  );
}

function MobileAuth() {
  useEffect(() => { goToApp() }, [])
  function goToApp() {
    const urlParams = new URLSearchParams(window.location.search);  
    const code = urlParams.get('code')
    const allowedScopes = urlParams.get('scope')

    window.location.href = `windmateapp://authenticated?scope=${allowedScopes}&code=${code}`
  }
  
  return (<div>Redirecting...</div>)
}

export default App;
