import './Subscribe.css'
import checkmark from './../../Assets/checkmark.png'
import exclamationmark from './../../Assets/exclamationmark.png'
import cross from './../../Assets/cross.png'
import arrow from './../../Assets/arrow.png'
import check from './../../Assets/check.png'
import logo from './../../Assets/HomePage_Logo.png'
import { useState, useEffect } from 'react'
import { Firestore } from '../../firebase'
import { StravaAPI } from '../../Helpers/StravaAPIHelper'
import { SmallLoadingIndicator } from '../../Components/LoadingIndicator'
import { Stripe } from '../../Helpers/Stripe'
import { Analytics } from '../../Helpers/Analytics'


export function Subscribe({cookies, setCookie}) {

    const [subscribed, setSubscribed] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => { Analytics.logPageView("Subscribe Page", "/subscribe", cookies, setCookie); loadSubscriptionState() }, [])
    useEffect(() => { loadSubscriptionState() }, [cookies])

    async function loadSubscriptionState() {
        if (isNotLoggedIn()) { return; }
        setSubscribed(await Firestore.getSubscriptionStatus(cookies.user.athlete.id))
    }

    function isNotLoggedIn() { return (cookies.user === undefined || cookies.user === null || Object.keys(cookies.user).length === 0 || cookies.user.athlete === null) }

    async function subscribe() {
        if (isNotLoggedIn()) { StravaAPI.authenticate(); return; }

        setLoading(true);
        Stripe.startSubscriptionSession(cookies.user.athlete.id)
    }

    async function manageSubscription() {
        setLoading(true);
        Stripe.startSubscriptionManagementSession(cookies.user.athlete.id)
    }

    return (
        <div>
            <div className="Content">
                <h1>Subscription Plans</h1>
                <div className="SubscriptionGrid">
                    <div className='SubscriptionPlanCard'>
                        <h1 className='SubscriptionCardTitle'>Free Plan</h1>
                        <div className='SubscriptionFeatureList'>
                            <div>
                                <img src={checkmark} alt='checkmark'/>
                                <p>Weather condition forecasts up to 12 hours ahead</p>
                            </div>
                            <div>
                                <img src={checkmark} alt='checkmark'/>
                                <p>Strava plugin</p>
                            </div>
                            <div style={{opacity: 0.5}}>
                                <img src={cross} alt='x'/>
                                <p>Wind analysis for <b>personal record & previous efforts</b></p>
                            </div>
                            <div style={{opacity: 0.5}}>
                                <img src={cross} alt='x'/>
                                <p>Advanced metrics</p>
                            </div>
                        </div>
                        <p className='CurrentPlan' hidden={subscribed}>Your current plan</p>
                    </div>

                    <div style={{display: "grid", pointerEvents: loading ? "none" : "all"}}>
                        <div className='SubscriptionPlanCard WindMatePlus' style={{gridRow: 1, gridColumn: 1, display: loading ? "block" : "none"}}><SmallLoadingIndicator/></div>
                        <div className="SubscriptionPlanCard WindMatePlus" style={{gridRow: 1, gridColumn: 1, opacity: loading ? "0.3" : "1"}}>
                            <h1 className='SubscriptionCardTitle'>WindMate+</h1>
                            <div className='SubscriptionFeatureList'>
                                <div>
                                    <img src={exclamationmark} alt='exclamationmark'/>
                                    <p>Weather condition forecasts up to <b>2 days</b> ahead</p>
                                </div>
                                <div>
                                    <img src={checkmark} alt='checkmark'/>
                                    <p>Strava plugin</p>
                                </div>
                                <div>
                                    <img src={checkmark} alt='checkmark'/>
                                    <p>Wind analysis for <b>personal record & previous efforts</b></p>
                                </div>
                                <div>
                                    <img src={checkmark} alt='checkmark'/>
                                    <p>Advanced metrics: <b>wind speed</b>, <b>temperature</b> & <b>air pressure</b> along the segment</p>
                                </div>
                            </div>

                            {
                                !subscribed ? 
                                <div onClick={subscribe} className="WindMatePlusButton">
                                    <h1>$2.50</h1>
                                    <p>/month</p>
                                    <img src={arrow}/>
                                </div> 
                                : <div className="SubscribedIndicator">
                                    <img src={check}/>
                                    <h1>Subscribed</h1>
                                </div>
                            }

                            {
                                !subscribed ?
                                <p className='SubscriptionDetail'>Billed monthly, cancel anytime</p> :
                                <p className='SubscriptionDetail Unsubscribe' onClick={manageSubscription}>Manage subscription</p>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="Subscribe Alt Content">
                <h1>Frequently Asked Questions</h1>
                <div className="Explanation1">
                    <p>WindMate is a passion project of two college kids who wanted to create something cool and meaningful. Whether you're a seasoned athlete, a weekend warrior, or simply someone who loves the great outdoors, WindMate is an awesome tool to gain insight into the meteorological factors that await your next big adventure.<br/><br/>With that being said, here are potential answers to some questions you may have:</p>
                </div>

                <h1>Why the price tag?</h1>
                <p><b>Accurate data is expensive.</b> WindMate uses tomorrow.io to obtain hyperlocal wind data, meaning you are sure to get the most accurate and updated meteorological forecasts (within the limits of scientific possibility, of course). When you upgrade your plan, your money goes directly towards reimbursing our commercial API license. We appreciate your support, truthfully.</p>

                <h1>Can I cancel my subscription at any time?</h1>
                <p><b>Of course!</b> Your subscription automatically renews every month, but you can cancel it whenever you want, <b> free of charge</b>. We're sad to see you go... but we understand.</p>

                <h1>Have unanswered questions?</h1>
                <p>If you have any other questions or want to provide feedback on your user experience, please send us an email at <b>contact@windmate.io.</b> </p>
            
                <img className="CenteredImage" src={logo} height="300px"/>
            </div>
        </div>
    )
}