import { MapContainer, TileLayer, Polyline, useMap } from 'react-leaflet'
import { useEffect, useState } from 'react'
import { Firestore, saveSegmentCoordsToDB } from './firebase';
import { formatCoordinates } from './Helpers/MapHelper';
import { StravaAPI } from './Helpers/StravaAPIHelper';

export function SegmentCard({segment, accessToken, preferredUnits}) {

    const [map, setMap] = useState(null);
    
    useEffect(() => {loadMap(segment.id)}, [segment])

    async function loadMap(segmentID) {
        if (segmentID === undefined) { return; }

        let coords = await Firestore.getSegmentCoordinates(segmentID)
        if (coords === null) { coords = await StravaAPI.getSegmentCoordinates(segmentID, accessToken); saveSegmentCoordsToDB(segmentID, coords) }

        setMap(formatCoordinates(coords))
    }

    function goToSegment() {
        window.location.href = `/segment?id=${segment.id}`
    }

    function MapResizer({bounds}) {
        const map = useMap()

        useEffect(() => {
            if (bounds === null) { return; }
            map.fitBounds(bounds)
        }, [bounds])

        return (<div/>)
    }

    function metricDistance() { return (segment.distance / 1000).toFixed(1) + 'km' }
    function imperialDistance() { return ((segment.distance / 1000) * 0.62137).toFixed(1) + 'mi' }

    return (
        <div className="SegmentCard" onClick={goToSegment}>
                
            <div className='SegmentInfoHeader'>
                <h3>{segment.name}</h3>
                <p className="SegmentLocation">{[segment.city, segment.state, segment.country].filter(s => (s !== "" && s !== null)).join(', ')}</p>
            </div>

            {
                (map === null) ? <div className='MapPlaceholder'/> :
                <div className="SegmentCardBottom">
                    <MapContainer className="SmallSegmentMap" center={[0, 0]} zoom={2} scrollWheelZoom={false} zoomControl={false} zoomSnap={0} attributionControl={false} dragging={false}>
                        <TileLayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"/>
                        <Polyline positions={map === undefined ? [] : map.coords} color="var(--navyblue)"/>
                        <MapResizer bounds={map === undefined ? [[0,0],[1,1]] : map.bounds}/>
                    </MapContainer>

                    <div className='BottomRight'>
                        <p>{preferredUnits === 'meters' ? metricDistance() : imperialDistance()}</p>
                        <p>{segment.average_grade.toFixed(1)}%</p>
                    </div>
                </div>
            }
        </div>
    )
}