import './EffortRow.css'
import power from './Assets/power.png'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { Firestore } from './firebase'
import { ConditionsBar } from './Pages/Activity/Activity'

export function EffortRow({effort, subscribed, accessToken, preferredUnits}) {

    useEffect(() => { getEffortConditions() }, [subscribed])
    const [conditions, setConditions] = useState(null)

    function formatTime(seconds) {
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds - hours * 3600) / 60) 
        const sec = seconds - hours * 3600 - minutes * 60

        let minutesString = `${minutes}`
        if (minutes < 10) { minutesString = `0${minutesString}` }

        let secString = `${sec}`
        if (sec < 10) { secString = `0${secString}` }

        if (hours === 0) { return `${minutes}:${secString}` }
        else { return `${hours}:${minutesString}:${secString}` }
    }

    function formatDate(dateString) {
        const date = new Date(dateString);

        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate
    }

    async function getEffortConditions() {
        if (!subscribed) { return; }
        const fromDB = await Firestore.getEffortConditions(effort.id);
        if (fromDB !== null) { setConditions(fromDB); return }

        try {
            const response = await axios.get('https://us-central1-mygame-c2230.cloudfunctions.net/effort-conditions', { params: {
                segment_id: effort.segment.id,
                access_token: accessToken,
                timestamp: effort.start_date,
                effort_id: effort.id
            }});
            setConditions(response.data)
        } catch (err) {
            console.log(err);
        }
    }

    function metricSpeed() { return ((effort.distance / effort.elapsed_time) * 3.6).toFixed(1) + 'km/h' }
    function imperialSpeed() { return ((effort.distance / effort.elapsed_time) * 3.6 * 0.62137).toFixed(1) + 'mph' }

    return (
        <div className="EffortRow">
            <div className='SegmentInfo'>
                <h3>{formatDate(effort.start_date_local)}</h3>
                <a className='ViewOnStrava' href={effort === undefined ? "" : `https://strava.com/activities/${effort.activity.id}`}>View on Strava</a>
                </div>
                    <p className='Detail Time'><p className='DetailCaption'>Time<br/></p>{formatTime(effort.elapsed_time)}</p>
                    <p className='Detail Speed'><p className='DetailCaption'>Speed<br/></p>{preferredUnits === 'meters' ? metricSpeed() : imperialSpeed()}</p>
                    <p className='Detail HR'><p className='DetailCaption'>HR<br/></p>{effort.average_heartrate === undefined ? "-" : `${effort.average_heartrate.toFixed(0)}bpm`}</p>
                    <p className='Detail PWR'><p className='DetailCaption'>Power<br/></p>{effort.average_watts === undefined ? "-" : `${effort.average_watts.toFixed(0)}w`}{effort.device_watts === true ? <img src={power}/> : ""}</p>
                    {/* { subscribed === false ? <p className='EffortConditions'>Get <b>WindMate+</b> to unlock</p> : conditions === null ? <p className='EffortConditions'>Loading...</p> : <p className='EffortConditions'><b className='WindDetail Headwind'>{`${conditions.HeadWind.toFixed(1)}`}%</b> | <b className='WindDetail Crosswind'>{`${(100 - conditions.HeadWind - conditions.TailWind).toFixed(1)}`}%</b> | <b className='WindDetail Tailwind'>{`${conditions.TailWind.toFixed(1)}`}%</b> @ <b>{conditions.WindSpeed.toFixed(1)}mph</b></p> } */}
                    {
                        subscribed === false ?  <p className='EffortConditions'>Get <b>WindMate+</b> to unlock</p> :
                        conditions === null ? <p>Loading...</p> :
                        <ConditionsBar className="EffortConditions" headwind={conditions.HeadWind} crosswind={Math.abs(100 - conditions.HeadWind - conditions.TailWind)} tailwind={conditions.TailWind} windspeed={preferredUnits === 'meters' ? `${(conditions.WindSpeed * 1.609344).toFixed(1)}km/h` : `${(conditions.WindSpeed).toFixed(1)}mph`}/>
                    }
                <div>
            </div>
        </div>
    )
}