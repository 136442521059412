import './SignInPrompt.css'
import { Analytics } from '../Helpers/Analytics'
import { useEffect } from 'react'

export function PageNotFound({cookies, setCookie}) {

    useEffect(() => { Analytics.logPageView("404 Page", "/non-existing", cookies, setCookie) }, [])

    return (
        <div className='SignInPromptContainer'>
            <h1>:/</h1>
            <h3>This page does not exist!</h3>
            <div className="ButtonDiv" style={{backgroundColor: "var(--color-1)", color: "white", padding: "10px", borderRadius: "10px"}} onClick={() => { window.location.href = '/' }}>Go back to the home page</div>
        </div>
    )
}