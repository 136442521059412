import axios from "axios";

export class WeatherDataHelper {

    static apiKey = "irAISdmeqrnlgryEybUjuVnTZBx7evPy";

    static async effortConditions(effortID) {

    }

    static async routeImpact(coordinates, estimatedTime, forecastLength) {
        const MAX_CHUNK_DIST = 10000
        
        let chunks = []
        let currentChunk = [coordinates[0]]
        let currentChunkDist = 0.0
        let totalDist = 0.0

        for (const point of coordinates.slice(1)) {
            const additionalDistance = this.distanceBetween(currentChunk[currentChunk.length - 1], point);
            totalDist += additionalDistance;

            if ((currentChunkDist + additionalDistance) > MAX_CHUNK_DIST) {
                chunks.push({distance: currentChunkDist, coordinates: currentChunk});
                currentChunkDist = additionalDistance;
                currentChunk = [currentChunk[currentChunk.length - 1], point]
            } else {
                currentChunkDist += additionalDistance;
                currentChunk.push(point)
            }
        }
        
        chunks.push({distance: currentChunkDist, coordinates: currentChunk})

        let cumulativeDist = 0.0
        let chunkImpact = []

        for (const chunk of chunks) {
            const estimatedTimeSinceStart = estimatedTime * ((cumulativeDist + chunk.distance / 2) / totalDist)
            const timestamp = new Date()
            timestamp.setSeconds(timestamp.getSeconds() + estimatedTimeSinceStart)
            const weatherData = await this.getWeatherData(chunk.coordinates, timestamp, forecastLength)
            
            chunkImpact.push({
                ...weatherData,
                distance: chunk.distance
            })

            cumulativeDist += chunk.distance
        }

        let overallData = {}

        for (let i = 0; i < forecastLength; i++) {
            let totalHumidity = 0.0
            let totalPressureSurfaceLevel = 0.0
            let totalTemperature = 0.0
            let totalWindDirection = 0.0
            let totalWindGust = 0.0
            let totalWindSpeed = 0.0
            let totalHeadwind = 0.0
            let totalTailwind = 0.0
            let sections = []

            for (const chunkData of chunkImpact) {
                const data = chunkData[Object.keys(chunkData)[i]]
                totalHumidity += data.humidity * chunkData.distance;
                totalPressureSurfaceLevel += data.pressureSurfaceLevel * chunkData.distance;
                totalTemperature += data.temperature * chunkData.distance;
                totalWindDirection += data.windDirection * chunkData.distance;
                totalWindGust += data.windGust * chunkData.distance;
                totalWindSpeed += data.windSpeed * chunkData.distance;
                totalHeadwind += data.headwind * chunkData.distance;
                totalTailwind += data.tailwind * chunkData.distance;
                sections = sections.concat(data.sections);
            }
            
            overallData[Object.keys(chunkImpact[0])[i]] = {
                startTime: Object.keys(chunkImpact[0])[i],
                humidity: totalHumidity / cumulativeDist,
                pressureSurfaceLevel: totalPressureSurfaceLevel / cumulativeDist,
                temperature: totalTemperature / cumulativeDist,
                windDirection: totalWindDirection / cumulativeDist,
                windGust: totalWindGust / cumulativeDist,
                windSpeed: totalWindSpeed / cumulativeDist,
                headwind: totalHeadwind / cumulativeDist,
                tailwind: totalTailwind / cumulativeDist,
                sections: this.mergeSections(sections)
            }
        }

        return overallData
    }

    static async getWeatherData(coordinates, timestamp, forecastLength) {
        const endTime = new Date(timestamp)
        endTime.setHours(endTime.getHours() + forecastLength)
        const medianCoordinate = coordinates[Math.floor(coordinates.length / 2)]

        try {
            const reponse = await axios.get(`https://api.tomorrow.io/v4/timelines?apikey=${this.apiKey}`, {
                params: {
                    "location": `${medianCoordinate[0]}, ${medianCoordinate[1]}`,
                    "fields": ["windDirection", "temperature", "windSpeed", "pressureSurfaceLevel", "humidity", "windGust"],
                    "units": "imperial",
                    "timesteps": ["1h"],
                    "startTime": timestamp.toISOString(),
                    "endTime": endTime.toISOString(),
                    "timezone": "UTC"
                }, headers: {
                    "accept": "application/json",
                    "content-type": "application/json"
                }
            })

            const timelines = reponse.data.data.timelines
            const data = {}

            for (const interval of timelines[0].intervals) {
                data[interval.startTime] = {
                    ...interval.values,
                    ...this.computeImpact(coordinates, interval.values)
                }
            }
            
            return data
        } catch (err) { console.log(err); return null }
    }

    static async getWeatherForecast(coordinates, numHours) {

        const endTime = `nowPlus${numHours}h`
        const medianCoordinate = coordinates[Math.floor(coordinates.length / 2)]

        try {
            const reponse = await axios.get(`https://api.tomorrow.io/v4/timelines?apikey=${this.apiKey}`, {
                params: {
                    "location": `${medianCoordinate[0]}, ${medianCoordinate[1]}`,
                    "fields": ["windDirection", "temperature", "windSpeed", "pressureSurfaceLevel", "humidity", "windGust"],
                    "units": "imperial",
                    "timesteps": ["1h"],
                    "startTime": "now",
                    "endTime": endTime,
                    "timezone": "UTC"
                }, headers: {
                    "accept": "application/json",
                    "content-type": "application/json"
                }
            })
            const timelines = reponse.data.data.timelines
            let data = {}

            for (const interval of timelines[0].intervals) {
                data[interval.startTime] = {
                    ...interval.values,
                    ...this.computeImpact(coordinates, interval.values)
                }
            }

            return data

        } catch (err) { console.log(err); return null }

    }

    static computeImpact(coordinates, conditions) {

        let [numTailWind, numHeadWind] = [0,0]
        let sections = []
        let currentWindType = null
        let currentLength = 0
        
        for (let i = 0; i < coordinates.length - 1; i++) {
            const dir = this.bearing(coordinates[i], coordinates[i+1])
            const windType = this.classifyWind(conditions.windDirection, dir);

            if (windType === 'headwind') { numHeadWind += 1 }
            else if (windType === 'tailwind') { numTailWind += 1 }

            if (currentWindType === null) {
                currentWindType = windType
                currentLength = 1
            } else if (currentWindType === windType) {
                currentLength += 1
            } else {
                sections.push({windType: currentWindType, length: currentLength})
                currentWindType = windType
                currentLength = 1
            }
        }

        sections.push({windType: currentWindType, length: currentLength})

        return {
            headwind: numHeadWind / (coordinates.length - 1),
            tailwind: numTailWind / (coordinates.length - 1),
            sections: sections
        }
    }

    static classifyWind(windDirection, headed) { 
        let angle = Math.abs(windDirection - headed)
        if (angle > 180) { angle = 360 % angle }
    
        if (angle < 50) { return 'tailwind' }
        else if (angle < 100) { return 'crosswind' }
        return 'headwind'
    }

    static bearing(p1, p2) {
        const dLon = (p2[1] - p1[1]) * (Math.PI / 180)
        const [lat1, lat2] = [(p1[0]) * (Math.PI / 180), (p2[0]) * (Math.PI / 180)]

        const y = Math.sin(dLon) * Math.cos(lat2)
        const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon)

        let brng = Math.atan2(y, x)
        brng = brng * (180 / Math.PI)

        return (brng + 180) % 360
    }

    static distanceBetween(p1, p2) {
        const R = 6373000.0 // Approximate radius of earth in m

        const [lat1, lon1, lat2, lon2] = [p1[0] * (Math.PI / 180), p1[1] * (Math.PI / 180), p2[0] * (Math.PI / 180), p2[1] * (Math.PI / 180)]
        const [dlon, dlat] = [lon2 - lon1, lat2 - lat1]

        const a = Math.sin(dlat / 2)**2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2)**2
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        return R * c   
    }

    static mergeSections(sectionsArr) {
        let sections = []
        for (const section of sectionsArr) {
            if (sections.length > 1 && sections[sections.length - 1].windType == section.windType) { sections[sections.length - 1] = { windType: section.windType, length: sections[sections.length - 1].length + section.length} }
            else { sections.push(section) }
        }
        return sections
    }
}