import './SignInPrompt.css'
import strava from '../Assets/connect-with-strava.svg'
import { StravaAPI } from '../Helpers/StravaAPIHelper'

export function SignInPrompt() {
    return (
        <div className='SignInPromptContainer'>
            <h1>:/</h1>
            <h3>You need to be signed in to access this page!</h3>
            <img src={strava} className='SignIn' onClick={StravaAPI.authenticate}/>
        </div>
    )
}