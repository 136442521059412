import { useEffect, useState } from "react"
import { Analytics } from "../Helpers/Analytics";
import { drawForecast, drawPressureChart, drawTempChart, drawWindChart, formatDate } from "../Helpers/ForecastChartHelper";
import { StravaAPI, refreshTokensIfNeeded } from "../Helpers/StravaAPIHelper";
import { decode } from "@mapbox/polyline";
import { WeatherDataHelper } from "../Helpers/WeatherDataHelper";
import { Firestore } from "../firebase";
import { useMap } from "react-leaflet";
import { SignInPrompt } from "../Components/SignInPrompt";
import lock from '../Assets/lock.png'
import { LoadingIndicator, SmallLoadingIndicator } from "../Components/LoadingIndicator";
import ruler from '../Assets/ruler.png'
import mountain from '../Assets/mountain.png'
import DurationPicker from "../Components/DurationPicker/DurationPicker";
import { MapContainer, TileLayer, Circle, Polyline, ZoomControl } from "react-leaflet";
import { Fragment } from "react";

export function RoutePage({cookies, setCookie}) {

    const [routeData, setRouteData] = useState(null);
    const [weatherData, setWeatherData] = useState(null);
    const [preferredUnits, setPreferredUnits] = useState('imperial');
    const [selectedTimeIndex, setSelectedTimeIndex] = useState(null);
    const [isSubscribed, setIsSubscribed] = useState(false);

    const [chart, setChart] = useState(null)
    const [tempChart, setTempChart] = useState(null)
    const [windChart, setWindChart] = useState(null)
    const [pressureChart, setPressureChart] = useState(null)

    const [hours, setHours] = useState(12)

    const [bounds, setBounds] = useState(null)
    const [segmentStart, setSegmentStart] = useState([0,0])
    const [segmentEnd, setSegmentEnd] = useState([0,0])
    const [circleRadius, setCircleRadius] = useState(25)
    
    let coords = null;


    useEffect(() => { Analytics.logPageView("Route Page", "/route", cookies, setCookie); ; getData() }, [])
    useEffect(() => { renderCharts() }, [weatherData, hours])
    useEffect(() => { rerenderColors() }, [cookies.highContrast])
    useEffect(() => { renderMap() }, [routeData])

    function isNotLoggedIn() { return (cookies.user === undefined || cookies.user === null || Object.keys(cookies.user).length === 0 || cookies.user.athlete === null) }

    function rerenderColors() {
        if (weatherData === null || weatherData === undefined) { return; }
        setTimeout(function() {
            drawForecast(weatherData, chart, setChart, hours, setSelectedTimeIndex)
        }, 100);
    }

    function coordinates(route) {
        if (coords === null) { coords = decode(route.map.polyline) }
        return coords
    }

    async function getData() {
        if (isNotLoggedIn()) { return }
        // Get a valid access token 
        const aToken = await refreshTokensIfNeeded(cookies, setCookie);
        getUserPreferences(aToken)

        // Read parameters from URL
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id')

        // These are all done in parallel but return at different times
        const data = await StravaAPI.getRoute(id, aToken)
        setRouteData(data)

        const subscribed = await Firestore.getSubscriptionStatus(cookies.user.athlete.id)
        setIsSubscribed(subscribed)
        const weatherData = await WeatherDataHelper.routeImpact(coordinates(data), data.estimated_moving_time, subscribed ? 48 : 12)
        // const weatherData = await WeatherDataHelper.getWeatherForecast(coordinates(data), subscribed ? 48 : 12)
        setWeatherData(weatherData)
    }

    async function getUserPreferences(accessToken) {
        if (cookies.user.allowedScopes === undefined || cookies.user.allowedScopes.includes('profile:read_all')) {
            const userInfo = await StravaAPI.getUserInfo(accessToken)
            const preference = userInfo.measurement_preference
            setPreferredUnits(preference)
        } else {
            const preference = (['US', 'us', 'United States', 'USA', 'U.S.'].includes(cookies.user.athlete.country)) ? 'imperial' : 'meters'
            setPreferredUnits(preference)
        }
    }

    function renderMap() {
        if (routeData === null) { return; }
        const coords = coordinates(routeData)
        let [maxX, maxY, minX, minY] = [-10000, -10000, 100000, 100000]

        for (const coord of coords) {
            maxX = Math.max(maxX, coord[0]);
            minX = Math.min(minX, coord[0]);
            maxY = Math.max(maxY, coord[1]);
            minY = Math.min(minY, coord[1]);
        }

        const maxSizeDiff = Math.max(maxX - minX, maxY - minY)
        const padding = maxSizeDiff * 0.1;
        setBounds([[minX - padding, minY - padding], [maxX + padding, maxY + padding]])
        setSegmentEnd(coords[coords.length - 1])
        setSegmentStart(coords[0])
        setCircleRadius(maxSizeDiff * 1200)
    }

    function renderCharts() {
        if (weatherData === null || weatherData === undefined) { return; }
        drawForecast(weatherData, chart, setChart, hours, setSelectedTimeIndex)
        drawTempChart(weatherData, tempChart, setTempChart, hours, preferredUnits);
        drawWindChart(weatherData, windChart, setWindChart, hours, preferredUnits);
        drawPressureChart(weatherData, pressureChart, setPressureChart, hours);
    }

    function MapResizer({bounds}) {
        const map = useMap()

        useEffect(() => {
            if (bounds === null) { return; }
            map.fitBounds(bounds)
        }, [bounds])

        return (<div/>)
    }

    function Locked({additional}) {
        return (<div style={{width: "100%", height: "100%", color: "black", alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <img style={{height: "40px"}} src={lock}/>
            <p style={{marginBottom: 0}}>Get <b>WindMate+</b> to unlock {additional}</p>
        </div>)
    }

    function creationDate() { return new Date(routeData.created_at).toLocaleDateString(undefined, {year: 'numeric', month: 'long', day: 'numeric',}) }
    function metricDistance(d) { return (d / 1000).toFixed(1) + 'km' }
    function imperialDistance(d) { return ((d / 1000) * 0.62137).toFixed(1) + 'mi' }
    function metricElevation(e) { return e.toFixed(0) + 'm' }
    function imperialElevation(e) { return (e * 3.28084).toFixed(0) + 'ft' }

    function ColoredPolyline() {
        let progress = 0;

        const style = getComputedStyle(document.body)
        const green = style.getPropertyValue('--windGreen')
        const yellow = style.getPropertyValue('--windYellow')
        const red = style.getPropertyValue('--windRed')

        const timestamps = Object.keys(weatherData)
        timestamps.sort()
        const mostRecentTimestamp = timestamps[selectedTimeIndex === null ? 0 : selectedTimeIndex]
        const mostRecentWeatherData = weatherData[mostRecentTimestamp]

        const coords = coordinates(routeData)

        return (
            <div>
                {Object.values(mostRecentWeatherData.sections).map((data, i) => (
                    <Fragment key={progress}>
                        <Polyline positions={coords.slice(Math.max(0, progress - 1), progress + data.length + ((i === (Object.values(mostRecentWeatherData.sections).length - 1)) ? 1 : 0))} lineJoin="round" weight={5} color={data.windType === "headwind" ? red : (data.windType === "crosswind" ? yellow : green)} />
                        { progress = progress + data.length }
                    </Fragment>
                ))}
            </div>
        )
    }

    function formattedEstimatedTime() {
        const hrs = Math.floor(routeData.estimated_moving_time / 3600)
        const m = Math.floor( (routeData.estimated_moving_time - hrs * 3600) / 60 )

        if (hrs === 0) { return `${m} ${ m === 1 ? 'minute' : 'minutes'}` }
        else { return `${hrs} ${ hrs === 1 ? 'hour' : 'hours'} and ${m} ${ m === 1 ? 'minute' : 'minutes'}` }
    }

    if (isNotLoggedIn()) {
        return (<SignInPrompt/>)
    } else if (routeData === null) {
        return (
         <div style={{height: "70vh"}}>
            <LoadingIndicator/>
            <canvas id="forecast" hidden></canvas>
        </div>)
    }

    return (
        <div>
            <div className="SegmentContent">
        
            <div className="SegmentSummary">
                <h1 style={{color: "white"}}>{routeData.name}</h1>

                <div className="SegmentSummaryDistElev">
                    <img src={ruler}/>
                    <p>{preferredUnits === 'meters' ? metricDistance(routeData.distance) : imperialDistance(routeData.distance)}</p>

                    <img src={mountain}/>
                    <p>{preferredUnits === 'meters' ? metricElevation(routeData.elevation_gain) : imperialElevation(routeData.elevation_gain)}</p>
                </div>
            </div>
            
            <p className="SegmentDetails">Created on {creationDate()} by {routeData.athlete.firstname} {routeData.athlete.lastname} • <a href={`https://strava.com/routes/${routeData.id_str}`}>View on Strava</a></p>
            <p className="SegmentDetails">Forecasts made based on an estimated moving time of {formattedEstimatedTime()}</p>

            <div className="UpperRow">
                <div>
                    <h2 className="CardTitle">{selectedTimeIndex === null || selectedTimeIndex === 0 ?'Conditions if you start now' : `Conditions starting at ${formatDate(Object.keys(weatherData)[selectedTimeIndex])}`}</h2>
                    <div className="LiveCard">
                        {
                            (routeData === null) ? <div>Loading...</div> :
                            <div style={{display: "grid", gridRow: 1, gridColumn: 1}}>

                                <MapContainer className="LiveMap" center={[0,0]} zoom={12} scrollWheelZoom={false} zoomControl={false} zoomSnap={0} attributionControl={false} dragging={(window.screen.width > 1000)}>
                                    <TileLayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"/>
                                    <Circle center={segmentStart} pathOptions={{ fillColor: 'green', color: 'green' }} fillOpacity="1" radius={circleRadius} />
                                    <Circle center={segmentEnd} pathOptions={{ fillColor: 'red', color: 'red' }} fillOpacity="1" radius={circleRadius} />
                                    {
                                        (weatherData === null) ? 
                                        <Polyline positions={coordinates(routeData)} color="rgba(0,0,0,0.7)" /> :
                                        <ColoredPolyline/>
                                    }
                                    <MapResizer bounds={bounds}/>
                                    <ZoomControl position="topright" />
                                </MapContainer>
                            </div>
                            
                        }
                    </div>
                </div>
                
                <div>
                    <div className="ForecastCardHeader" style={{display: "flex", alignItems: "baseline"}}>
                        <h2>Forecasted Conditions</h2>
                        <DurationPicker selected={hours} setDuration={setHours}/>
                    </div>
                    
                    <div className="ForecastCard">
                        { (weatherData === null) ? <SmallLoadingIndicator/> : (isSubscribed === true || hours === 12) ? 
                        <canvas onMouseLeave={() => { setSelectedTimeIndex(null) }} className='ConditionsChart' id="forecast"/> : <Locked additional=" forecasts up to 2 days ahead"/> }
                    </div>
                </div>
            </div>

            <div className="SmallChartGrid" style={{display: "grid", gap: "20px"}}>
                <div className="DottedBackground" style={{display: "flex", flexDirection: "column", color: "#3C5B6F",borderRadius: '15px'}}>
                    <h3 style={{marginTop:"5px", fontSize: '20px', textAlign: 'center'}}>Wind Speed ({ preferredUnits === 'meters' ? "kph" : "mph" })</h3>
                    <div style={{width: "100%", borderRadius: "15px", height: "200px"}}>
                        {isSubscribed || hours === 12 ? weatherData === null ? <SmallLoadingIndicator/> : <canvas style={{padding: "10px"}} id="windspeed"/> : <Locked/>}
                    </div>
                </div>

                <div className="DottedBackground" style={{display: "flex", flexDirection: "column", color: "#3C5B6F", borderRadius: '15px'}}>
                    <h3 style={{marginTop:"5px", fontSize: '20px', textAlign: 'center'}}>Temperature ({ preferredUnits === 'meters' ? "°C" : "°F" })</h3>
                    <div style={{width: "100%", borderRadius: "15px", height: "200px"}}>
                        {isSubscribed || hours === 12 ? weatherData === null ? <SmallLoadingIndicator/> :  <canvas style={{padding: "10px"}} id="temperature"/> : <Locked/>}
                    </div>
                </div>

                <div className="DottedBackground"  style={{display: "flex", flexDirection: "column", color: "#3C5B6F", borderRadius: '15px'}}>
                    <h3 style={{marginTop:"5px", fontSize: '20px', textAlign: 'center'}}>Air Pressure (hPa)</h3>
                    <div style={{width: "100%", borderRadius: "15px", height: "200px"}}>
                        {isSubscribed || hours === 12 ? weatherData === null ? <SmallLoadingIndicator/> :  <canvas style={{padding: "10px"}} id="airpressure"/> : <Locked/>}
                    </div>
                </div>
            </div>

        </div>
        </div>
    )
}