import { useState, useEffect } from 'react'
import { getPluginState, Firestore } from '../../firebase'
import Mockup from './../../Assets/plugin-mockup-2.png'
import './Plugin.css'
import checkmark from './../../Assets/checkmark-blue.png'
import { StravaAPI } from '../../Helpers/StravaAPIHelper'
import logo from '../../Assets/HomePage_Logo.png'
import { Analytics } from '../../Helpers/Analytics'

export default function Plugin({cookies, setCookie}) {

    const [enabled, setEnabled] = useState(false)

    useEffect(() => { Analytics.logPageView("Plugin Page", "plugin", cookies, setCookie); loadPluginState() }, [])

    function isNotLoggedIn() { return (cookies.user === undefined || cookies.user === null || Object.keys(cookies.user).length === 0 || cookies.user.athlete === null) }

    async function loadPluginState() {
        if (isNotLoggedIn()) { return }
        setEnabled(await Firestore.getPluginStatus(cookies.user.athlete.id));
    }

    function enablePlugin() {
        if (isNotLoggedIn() || cookies.user.allowedScopes === undefined || !cookies.user.allowedScopes.includes('activity:write')) {
            StravaAPI.authenticate(false, 'request_plugin_permissions')
            return;
        }
        setEnabled(true)
        Firestore.setPluginStatus(cookies.user.athlete.id, true)
    }

    function disablePlugin() {
        setEnabled(false)
        Firestore.setPluginStatus(cookies.user.athlete.id, false)
    }

    return (
        <div className=''>
            <div className='HeroSection'>
                <div>
                <h1>Auto-generated segment analysis</h1>
                <p>WindMate's Strava plug-in analyzes the segments in your activities so you can get immediate feedback on your performance and share with your network.</p>
                
                {
                    enabled ? 
                    <div>
                        <div className='Enabled'>
                            <img src={checkmark}/>
                            <h3>Enabled</h3>
                        </div>

                        <div className='DisablePluginButton' onClick={disablePlugin}>Disable</div>
                    </div> : 
                    <div>
                        <div className='EnablePluginButton' onClick={enablePlugin}>
                            <p><b>{isNotLoggedIn() ? "Sign in to enable" : "Enable Plug-In"}</b></p>
                        </div>
                        {
                            isNotLoggedIn() || cookies.user.allowedScopes === undefined || cookies.user.allowedScopes.includes('activity:write') ? <div/> :
                            <div style={{opacity: 0.5, fontSize: "14px", marginRight: "100px", marginTop: 0}}>Note: you will be asked to give WindMate permission to upload activities to Strava. We won't, but we need it to edit your captions.</div>
                        }
                    </div>
                }
                </div>

                <img src={Mockup} className='PluginMockup'/>
            </div>

            <div className="Plugin Content Alt">
                <h1>What does the Plug-in do?</h1>
                <p>The WindMate Strava plugin automatically captions your rides with a detailed 
                    analysis of the conditions on the overall ride and individual segments you did that day - so you can better understand your performance.</p>
                
                <h1>Is it free?</h1>
                <p><b>Yes!</b> The Strava plugin is completely free.</p>
            
                <h1>Will I be able to turn it off?</h1>
                <p><b>Of course.</b> If you ever want to turn it off, simply come back to this page and click "disable". Simple as that :)</p>
                <img className="PluginImage" src={logo}/>
            </div>
        </div>
    )
}