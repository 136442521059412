import { Fragment } from "react"
import { useEffect, useState } from "react";
import { Firestore } from "../../firebase";
import { StravaAPI } from "../../Helpers/StravaAPIHelper";
import logo from '../../Assets/HomePage_Logo.png'
import strava from '../../Assets/connect-with-strava.svg'


export function Authenticated({cookies, setCookie}) {

    const [err, setErr] = useState(null);
    const [state, setState] = useState('')
    const [showPluginRecommendation, setShowPluginRecommendation] = useState(false);

    function isNotLoggedIn() { return (cookies === null || cookies.user === undefined || cookies.user === null || Object.keys(cookies.user).length === 0 || cookies.user.athlete === null) }


    async function finishAuth() {
  
        const urlParams = new URLSearchParams(window.location.search);
        
        const code = urlParams.get('code')
        const allowedScopes = urlParams.get('scope')
        const state = urlParams.get('state')
        if (allowedScopes === null) { setErr('Something went wrong :/ Please try again.'); return }

        if (state === 'max') { window.location.href = `appRedirect://mobile/authenticated?state=${state}&scope=${allowedScopes}&code=${code}` }

        if (!isNotLoggedIn() && state !== 'request_plugin_permissions') { return; }
        let loginInfo = await StravaAPI.getTokensFromCode(code, allowedScopes)
        if (loginInfo === null) { return }
        
        const athlete = await StravaAPI.getUserInfo(loginInfo.accessToken)
        setShowPluginRecommendation(!(await Firestore.getPluginStatus(athlete.id)))

        loginInfo.athlete = {
            firstname: athlete.firstname,
            lastname: athlete.lastname,
            id: athlete.id,
            city: athlete.city,
            country: athlete.country,
            state: athlete.state
        }

        loginInfo.allowedScopes = allowedScopes.split(',')
    
        setCookie('user', loginInfo, {path: '/', maxAge: 10000000});

        if (state === 'request_plugin_permissions') { 
            if (allowedScopes.includes('activity:write')) {
                setErr('Enabling plugin...')
                await Firestore.setPluginStatus(loginInfo.athlete.id, true);
                window.location.href = '/plugin';
            } else {
                setState(state)
                setErr('Missing permissions! Please try again, and make sure to check all the boxes.')
            }
        } else if (state.length > 10 && state.slice(0, 9) === 'activity_') {
            window.location.href = `/activity?id=${state.slice(9, )}`
        } else if (state.length > 6 && state.slice(0, 5) === 'goto_') {
            window.location.href = `/${state.slice(5, )}`
        }
    }

    useEffect(() => { 
        finishAuth() 
    }, [])

    function headline() {
        if (cookies.user.athlete === undefined) { return "Loading..." }
        const now = new Date()
        const currHours = now.getHours()
        if (currHours > 4 && currHours < 11) { return `Good Morning, ${cookies.user.athlete.firstname}`; }
        else if (currHours > 4 && currHours < 18) { return `Good Afternoon, ${cookies.user.athlete.firstname}` }
        else { return `Good Evening, ${cookies.user.athlete.firstname}` }
    }

    async function enablePlugin() {
        setErr('Enabling plugin...');
        await Firestore.setPluginStatus(cookies.user.athlete.id, true);
        window.location.href = '/plugin';
    }

    return (
        <Fragment>
            <div style={{paddingBottom: "100px", height: "calc(90vh - 60px)", width: "100vw", backgroundColor: "var(--color-1)", textAlign: "center"}}>
                <img className="CenteredImage" height="200px" src={logo} style={{paddingTop: "140px", paddingBottom: "20px"}}/>
                {
                    err === null ?
                    <h1 style={{color: "white", fontSize: "32px", margin: "0", padding: "0", paddingBottom: "30px", lineHeight: "1.3", padding: "20px"}}>{(!isNotLoggedIn()) ? headline() : "Authenticating..."}</h1> :
                    <h1 style={{color: "white", fontSize: "32px", margin: "0", padding: "0", paddingBottom: "30px", lineHeight: "1.3", padding: "20px"}}>{err}</h1>
                }

                {
                    (err === null && showPluginRecommendation && cookies.user.allowedScopes !== undefined && cookies.user.allowedScopes.includes('activity:write')) ? 
                    <p style={{color: "white"}}>Recommended: <u className="PointerOnHover" onClick={() => { enablePlugin() }}><b>Enable the Strava plug-in ></b></u><br/>or</p> :
                    <div style={{display: "none"}}/>
                }

                {
                    err === null || err === 'Enabling plugin...' ? 
                    <div className='FinishAuthButton' onClick={() => {window.location.href = `/segments`}} style={{backgroundColor: "white", borderRadius: "100px", padding: "9px", display: (isNotLoggedIn() || err === 'Enabling plugin...') ? 'none' : "inline"}}><b>Start browsing your segments</b></div> :
                    <div>
                        <p style={{color: "white", opacity: 0.7, marginTop: "-20px", padding: "20px"}}>If you keep running into issues, shoot us an email at <b>contact@windmate.io</b></p>
                        <img src={strava} className='SignIn' onClick={() => { StravaAPI.authenticate(true, state) }}/>
                    </div>
                }
            </div>
        </Fragment>
    )
}