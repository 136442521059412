import './LoadingIndicator.css'

export function LoadingIndicator() {
    return (
        <div className={`loading-container ${true ? 'visible' : ''}`}>
          <div className="loading-spinner"></div>
        </div>
    );
}

export function SmallLoadingIndicator() {
  return (
    <div className={`loading-container-small ${true ? 'visible' : ''}`}>
      <div className="loading-spinner-small"></div>
    </div>
);
}