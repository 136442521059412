import ReactGA from 'react-ga4';

export class Analytics {

    static logPageView(name, path, cookies, setCookie) {
        ReactGA.send({ hitType: "pageview", page: path, title: name });

        // Track traffic sources (first page visited in a browser)
        if (cookies.analytics === undefined) {
            const trafficSource = name.replace(' ', '_').toLowerCase()
            this.logTrafficSource(`traffic_from_${trafficSource}`)
            setCookie('analytics', { source: trafficSource });
        }
    }

    static logStravaAPIError(name) {
        ReactGA.event({
            category: 'Strava_API',
            action: 'Error',
            label: name,
        });
    }

    static logStripeError(name) {
        ReactGA.event({
            category: 'Stripe',
            action: 'Error',
            label: name,
        });
    }

    static logTrafficSource(name) {
        ReactGA.event({
            category: 'TrafficSource',
            action: name,
            label: name,
        });
    }

    static logKeyEvent(name) {
        ReactGA.event({
            category: 'KeyEvent',
            action: name,
            label: name,
        });
    }

}