import logo from './../../Assets/HomePage_Logo.png'
import { Analytics } from '../../Helpers/Analytics'
import { useEffect } from 'react'

export function About({cookies, setCookie}) {

    useEffect(() => { Analytics.logPageView("About Page", "/about", cookies, setCookie) }, [])

    return (
        <div className="About Content">
            <h1>What is WindMate?</h1>
            <p style={{color: "black"}}>If you're thinking to yourself "there are so many apps that give weather forecasts, how is this special?", we understand where you're coming from. 
                While it's true that many resources exist for weather data, there is not a single one that coherently combines information in a concise, intuitive, and helpful way. 
                We created this application to make viewing and planning segment efforts as easy as possible. You get the relevant weather data that effects a particulat segment without having to fuss around trying to find it on a map, mentally draw out its trajectory, and manually chose what timeframe you want to observe. WindMate gives you an hourly forecast that is simple and useful. That's it. </p>
            
            <div className='VerticalSpacer' style={{height: "20px"}}/>

            <h1>How does WindMate work?</h1>
            <p style={{color: "black"}}> We gather coordinate data defining a Strava segment and utilize it to assess weather conditions at each individual coordinate point. In our wind recommendation process, we analyze the trajectory of the segment alongside the wind trajectory to ascertain the resulting wind type. This analysis is conducted for each coordinate point along the segment polyline to determine the overall proportion of different wind types.
                We acquire commercial weather data from tomorrow.io, ensuring continuous refreshment and updates for maximum accuracy.</p>

            <div className='VerticalSpacer' style={{height: "20px"}}/>

            <h1>Why is your logo so cute?</h1>
            <p style={{color: "black"}}> We're so glad you asked! Our logo was conceptualized and designed by the very talented Anna Lee. We didn't name the little guy yet, so if you have any ideas shoot us an email at <b>contact@windmate.io</b></p>  
            
            <div className='VerticalSpacer' style={{height: "20px"}}/>
            
            <img className='CenteredImage' src={logo} height = '300px'/>
        </div>
    )
}