import { useEffect, useState } from 'react'
import mountain from '../../Assets/mountain.png'
import ruler from '../../Assets/ruler.png'
import { Firestore } from '../../firebase'
import { LoadingIndicator } from '../../Components/LoadingIndicator'
import { MapContainer, TileLayer, Polyline, useMap, ZoomControl, Circle } from 'react-leaflet'
import PolylineDecoder from "@mapbox/polyline"
import power from '../../Assets/power.png'
import MagnifyingGlass from '../../Assets/magnifyingglass.png'
import star from '../../Assets/star.png'
import { Fragment } from 'react'
import { Analytics } from '../../Helpers/Analytics'
import './Activity.css'

import '../Segment/Segment.css'


export function Activity({cookies, setCookie}) {

    useEffect(() => { Analytics.logPageView('Activity Page', '/activity', cookies, setCookie); getData() }, [])
    const [activityInfo, setActivityInfo] = useState(null);
    const [efforts, setEfforts] = useState(null)
    const [filteredEfforts, setFilteredEfforts] = useState(null)
    const [searchInput, setSearchInput] = useState("");
    const [activityDoesNotExist, setActivityDoesNotExist] = useState(false);

    function getData() {
        const urlParams = new URLSearchParams(window.location.search);
        const activityID = urlParams.get('id')
        Analytics.logTrafficSource(`activity_${activityID}`);
        getActivityInfo(activityID)
        getEfforts(activityID)
    }

    async function getActivityInfo(activityID) {
        setActivityInfo(await Firestore.getStoredActivtyInfo(activityID));
        setActivityDoesNotExist(true);
    }

    async function getEfforts(activityID) {
        const efforts = Object.values(await Firestore.getActivityEfforts(activityID)).sort((a,b) => (new Date(a.start_date_local) - new Date(b.start_date_local)))
        setEfforts(efforts)
        setFilteredEfforts(efforts)
    }

    // Invisible view used to resize the map using black magic
    function MapResizer({bounds}) {
        const map = useMap()

        useEffect(() => {
            if (bounds === null) { return; }
            map.fitBounds(bounds)
        }, [bounds])

        return (<div/>)
    }

    const handleChange = (e) => {
        e.preventDefault();

        const searchString = e.target.value;
        if (searchString === "") { setFilteredEfforts(efforts) }

        setFilteredEfforts(
            efforts.filter(s => {
                const search = searchString.toLowerCase().trim();
                const props = ['name'];
                return props.some(prop => (s[prop] || '').toLowerCase().includes(search));
            })
        )
        setSearchInput(e.target.value);
    };




    function ActivityMap() {
        const coordinates = PolylineDecoder.decode(activityInfo.polyline)
        console.log(coordinates.length)
        const start = coordinates[0]
        const end = coordinates[coordinates.length - 1]

        let [minX, maxX, minY, maxY] = [1000,-1000,1000,-1000]
        for (const p of coordinates) { minX = Math.min(minX, p[0]); maxX = Math.max(maxX, p[0]); minY = Math.min(minY, p[1]); maxY = Math.max(maxY, p[1]) }
        const center = [(minX + maxX) / 2, (minY + maxY) / 2]
        const maxSizeDiff = Math.max(maxX - minX, maxY - minY)
        const padding = maxSizeDiff * 0.1;
        const bounds = [[minX - padding, minY - padding], [maxX + padding, maxY + padding]]
        const circleRadius = maxSizeDiff * 1200

        return (
            <MapContainer className="LiveMap" center={center} zoom={12} scrollWheelZoom={false} zoomControl={false} zoomSnap={0} attributionControl={false} dragging={(window.screen.width > 1000)}>
                <TileLayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"/>
                <Circle center={start} pathOptions={{ fillColor: 'green', color: 'green' }} fillOpacity="1" radius={circleRadius} />
                <Circle center={end} pathOptions={{ fillColor: 'red', color: 'red' }} fillOpacity="1" radius={circleRadius} />
                {
                    <ColoredPolyline/>   
                    //<Polyline positions={coordinates} color="rgba(0,0,0,0.7)" />
                }
                <MapResizer bounds={bounds}/>
                <ZoomControl position="topright" />
            </MapContainer>
        )
    }

    function metricDistance(d) { return (d / 1000).toFixed(1) + 'km' }
    function imperialDistance(d) { return ((d / 1000) * 0.62137).toFixed(1) + 'mi' }
    function metricElevation(e) { return e.toFixed(0) + 'm' }
    function imperialElevation(e) { return (e * 3.28084).toFixed(0) + 'ft' }
    function formattedDate(d) { return new Date(d).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }).replace(',', ''); }
    function fTemperature(t) { return t.toFixed(1) + '°F' }
    function cTemperature(t) { return ((t - 32) * (5/9)).toFixed(1) + '°C' }
    function imperialSpeed(s) { return s.toFixed(1) + 'mph' }
    function metricSpeed(s) { return (s * 1.60934).toFixed(1) + 'km/h' }

    function decodeSection(s) { 
        const [_, windType, length] = /\('([^']+)', (\d+)\)/.exec(s);
        return [windType, parseInt(length)]
    }

    function ColoredPolyline() {
        let progress = 0;

        const style = getComputedStyle(document.body)
        const green = style.getPropertyValue('--windGreen')
        const yellow = style.getPropertyValue('--windYellow')
        const red = style.getPropertyValue('--windRed')

        const sections = activityInfo.wind_sections.map(s => decodeSection(s))
        
        return (
            <div>
                {Object.values(sections).map((data, i) => (
                    <Fragment key={progress}>
                        <Polyline positions={PolylineDecoder.decode(activityInfo.polyline).slice(Math.max(0, progress - 1), progress + data[1] + ((i === (Object.values(sections).length - 1)) ? 1 : 0))} lineJoin="round" weight={5} color={data[0].toLowerCase() === "headwind" ? red : (data[0].toLowerCase() === "crosswind" ? yellow : green)} />
                        { progress = progress + data[1] }
                    </Fragment>
                ))}
            </div>
        )
    }

    function isSignedInAsOwner() {
        return !(cookies.user === undefined || cookies.user.athlete === undefined || parseInt(cookies.user.athlete.id) !== parseInt(activityInfo.athlete_id))
    }

    function hiddenStatsString() {
        let hidden = [];
        if (activityInfo.power_hidden === true) { hidden.push("power") }
        if (activityInfo.heartrate_hidden === true) { hidden.push("heart rate") }
        if (activityInfo.speed_hidden === true) { hidden.push("speed") }
        return hidden.join(", ")
    }

    function rankedWinds() {
        let allWinds = []

        allWinds.push({type: 'Headwind', proportion: activityInfo.headwind})
        allWinds.push({type: 'Tailwind', proportion: activityInfo.tailwind})
        allWinds.push({type: 'Crosswind', proportion: 100 - activityInfo.tailwind - activityInfo.headwind})

        allWinds.sort((a,b) => a.proportion - b.proportion)
        return allWinds
    }

    function colorFromWindType(type) {
        if (type === 'Headwind') { return "var(--windRed)" }
        else if (type === 'Tailwind') { return "var(--windGreen)" }
        return "var(--windYellow)"
    }

    if (activityDoesNotExist === true && activityInfo === null) {
        return (
            <div style={{height: "80vh", alignContent: "center", textAlign: "center", padding: "20px"}}>
                <h2>Nothing to see here!</h2>
                <p>It seems like this activity was not recorded with the WindMate Strava plugin enabled.</p>
            </div>
        ) 
    }

    if (activityInfo === null) {
        return (<div style={{height: "70vh"}}><LoadingIndicator/></div>)
    }

    if (activityInfo.activty_status === 'processing') {
        return (
            <div style={{height: "80vh", alignContent: "center", textAlign: "center", padding: "20px"}}>
                <h2>This activity is still processing</h2>
                <p>Check back in a bit!</p>
            </div>
        )
    }

    if (activityInfo.private === true && !isSignedInAsOwner()) {
        return (
            <div style={{height: "80vh", alignContent: "center", textAlign: "center", padding: "20px"}}>
                <h2>This activity is private</h2>
                <p>If you are the owner of this activity, sign in to see your full weather condition breakdown.</p>
            </div>
        )
    }
    
    return (
        <div>
            <div className="Alt Content">
                <div className="SegmentSummary">
                    <h1>{activityInfo.name}</h1>

                    <div className="SegmentSummaryDistElev">
                        <img src={ruler}/>
                        <p>{activityInfo.preferred_units === 'meters' ? metricDistance(activityInfo.distance) : imperialDistance(activityInfo.distance)}</p>

                        <img src={mountain}/>
                        <p>{activityInfo.preferred_units === 'meters' ? metricElevation(activityInfo.total_elevation_gain) : imperialElevation(activityInfo.total_elevation_gain)}</p>
                    </div>
                </div>
                <p style={{margin: 0, opacity: 0.6, fontSize: "14px"}}>{activityInfo.athlete_name} • {formattedDate(activityInfo.start_date)} • <a style={{color: "white"}} href={`https://www.strava.com/activities/${activityInfo.activity_id}`}><u>View on Strava</u></a></p>

                {
                    (isSignedInAsOwner() && activityInfo.private === true) ? <p style={{margin: 0, opacity: 0.6, fontSize: "14px"}}>Note: only you can see this page, as the activity is private.</p> : <div style={{display: "none"}}/>
                }

                <div className="UpperRow">
                    {/* <div className='DesktopOnly'> */}
                    <div>
                        <h2 style={{marginLeft: 0}} className="CardTitle">Map</h2>
                        <div className="LiveCard">
                            <ActivityMap/>
                        </div>
                    </div>
                    
                    <div>
                        <div className="ForecastCardHeader" style={{display: "flex", alignItems: "baseline"}}>
                            <h2>Weather Overview</h2>
                        </div>
                        
                        <div className='ForecastCard StatsCard'>
                            <div className='WindPodium'>
                                <div className='PodiumBar' style={{height: `${Math.min(rankedWinds()[0].proportion * 2 + 10, 90)}%`}}>
                                    <h2>{rankedWinds()[0].proportion.toFixed(1)}%</h2>
                                    <p>{rankedWinds()[0].type}</p>
                                    <div className='WindTypeBar' style={{backgroundColor: colorFromWindType(rankedWinds()[0].type)}}/>
                                </div>
                                <div className='PodiumBar' style={{height: `${Math.min(rankedWinds()[2].proportion * 2 + 10, 90)}%`}}>
                                    <h2>{rankedWinds()[2].proportion.toFixed(1)}%</h2>
                                    <p>{rankedWinds()[2].type}</p>
                                    <div className='WindTypeBar' style={{backgroundColor: colorFromWindType(rankedWinds()[2].type)}}/>
                                </div>
                                <div className='PodiumBar' style={{height: `${Math.min(rankedWinds()[1].proportion * 2 + 10, 90)}%`}}>
                                    <h2>{rankedWinds()[1].proportion.toFixed(1)}%</h2>
                                    <p>{rankedWinds()[1].type}</p>
                                    <div className='WindTypeBar' style={{backgroundColor: colorFromWindType(rankedWinds()[1].type)}}/>
                                </div>
                            </div>

                            <div className='RideStats'>
                                <div className='RideStatColumn'>
                                    <p>Average Wind Speed</p>
                                    <h2>{activityInfo.preferred_units === 'meters' ? metricSpeed(activityInfo.wind_speed) : imperialSpeed(activityInfo.wind_speed)}</h2>
                                </div>

                                <div className='RideStatColumn'>
                                    <p>Average Temperature</p>
                                    <h2>{activityInfo.preferred_units === 'meters' ? cTemperature(activityInfo.temperature) : fTemperature(activityInfo.temperature)}</h2>
                                </div>

                                <div className='RideStatColumn'>
                                    <p>Average Wind Gusts</p>
                                    <h2>{isNaN(activityInfo.windgust) ? "--" : activityInfo.preferred_units === 'meters' ? metricSpeed(activityInfo.windgust) : imperialSpeed(activityInfo.windgust)}</h2>
                                </div>
                            </div>
                        </div>
                        {/* <div className="ForecastCard" style={{display: "grid", gridTemplateRows: "1fr 1fr 1fr", color: "black"}}> */}
                            {/* <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", textAlign: "center"}}>
                                <div style={{display: "flex", flexDirection: "column", marginTop: "auto", marginBottom: "auto"}}>
                                    <h1 style={{color: "var(--windGreen)", margin: 0, transform: `scale(${windTypeScale()[0]})`}}>{activityInfo.tailwind.toFixed(1)}%</h1>
                                    <p style={{opacity: 0.5, fontSize: "15px", margin: 0}}>Tailwind</p>
                                </div>
                                <div style={{borderLeft: "solid 0.5px rgba(0,0,0,0.3)", borderRight: "solid 0.5px rgba(0,0,0,0.3)", display: "flex", flexDirection: "column", marginTop: "auto", marginBottom: "auto"}}>
                                    <h1 style={{color: "var(--windYellow)", margin: 0, transform: `scale(${windTypeScale()[1]})`}}>{(100 - activityInfo.headwind - activityInfo.tailwind).toFixed(1)}%</h1>
                                    <p style={{opacity: 0.5, fontSize: "15px", margin: 0}}>Crosswind</p>
                                </div>
                                <div style={{display: "flex", flexDirection: "column", marginTop: "auto", marginBottom: "auto"}}>
                                    <h1 style={{color: "var(--windRed)", margin: 0, transform: `scale(${windTypeScale()[2]})`}}>{activityInfo.headwind.toFixed(1)}%</h1>
                                    <p style={{opacity: 0.5, fontSize: "15px", margin: 0}}>Headwind</p>
                                </div>
                            </div> */}

                            {/* <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", textAlign: "center", borderTop: "solid 0.5px rgba(0,0,0,0.3)",}}> 
                                <div style={{borderRight: "solid 0.5px rgba(0,0,0,0.3)", display: "flex", flexDirection: "column", marginTop: "auto", marginBottom: "auto"}}>
                                    <h1 style={{margin: 0}}>{activityInfo.preferred_units === 'meters' ? metricSpeed(activityInfo.wind_speed) : imperialSpeed(activityInfo.wind_speed)}</h1>
                                    <p style={{opacity: 0.5, fontSize: "15px", margin: 0}}>Avg. Wind Speed</p>
                                </div>

                                <div style={{display: "flex", flexDirection: "column", marginTop: "auto", marginBottom: "auto"}}>
                                    <h1 style={{margin: 0}}>{isNaN(activityInfo.windgust) ? "--" : activityInfo.preferred_units === 'meters' ? metricSpeed(activityInfo.windgust) : imperialSpeed(activityInfo.windgust)}</h1>
                                    <p style={{opacity: 0.5, fontSize: "15px", margin: 0}}>Avg. Gusts</p>
                                </div>
                            </div> */}

                            {/* <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", textAlign: "center", borderTop: "solid 0.5px rgba(0,0,0,0.3)",}}>
                                <div style={{display: "flex", flexDirection: "column", marginTop: "auto", marginBottom: "auto"}}>
                                    <h2 style={{color: "black", margin: 0, opacity: 0.8}}>{activityInfo.preferred_units === 'meters' ? cTemperature(activityInfo.temperature) : fTemperature(activityInfo.temperature)}</h2>
                                    <p style={{opacity: 0.5, fontSize: "15px", margin: 0}}>Avg. Temperature</p>
                                </div>
                                <div style={{borderLeft: "solid 0.5px rgba(0,0,0,0.3)", borderRight: "solid 0.5px rgba(0,0,0,0.3)", display: "flex", flexDirection: "column", marginTop: "auto", marginBottom: "auto"}}>
                                    <h2 style={{color: "black", margin: 0, opacity: 0.8}}>0.0mm</h2>
                                    <p style={{opacity: 0.5, fontSize: "15px", margin: 0}}>Precipitation</p>
                                </div>
                                <div style={{display: "flex", flexDirection: "column", marginTop: "auto", marginBottom: "auto"}}>
                                    <h2 style={{color: "black", margin: 0, opacity: 0.8}}>{activityInfo.humidity.toFixed(1)}%</h2>
                                    <p style={{opacity: 0.5, fontSize: "15px", margin: 0}}>Avg. Humidity</p>
                                </div>
                            </div> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>

            <div>
                <div className='Content'>

                    <div className="EffortList" style={{color: "black"}}>
                        <h2>Segment Efforts</h2>
                        <div className="SearchBar">
                            <img src={MagnifyingGlass}/>
                            <input
                            type="text"
                            placeholder="Search"
                            onChange={handleChange}
                            value={searchInput}/>
                        </div>

                        {
                            (activityInfo.power_hidden === false && activityInfo.heartrate_hidden === false && activityInfo.speed_hidden === false) ? <div style={{display: "none"}}/> :
                            <p style={{opacity: 0.5, fontSize: "13.5px"}}>{isSignedInAsOwner() ? `Only you can see: ${hiddenStatsString()}` : `The owner has hidden the following information: ${hiddenStatsString()}. If you are the owner of this activity, sign in to see them.`}</p>
                        }

                        <div className="Legend DesktopOnly">
                            <p><b>Segment Name</b></p>
                            <p><b>Time</b></p>
                            <p><b>Speed</b></p>
                            <p><b>Heart Rate</b></p>
                            <p><b>Power</b></p>
                            <p><b>Wind Conditions</b></p>
                        </div>

                        {
                            filteredEfforts === null ? <div/> : filteredEfforts.filter(e => { return e.starred === true }).map(function(e, idx) {
                                return (<SegmentRow key={e.id} effort={e} activity={activityInfo} isOwner={isSignedInAsOwner()}/>)
                            })
                        }

                        {
                            filteredEfforts === null ? <div/> : filteredEfforts.filter(e => { return e.starred === false }).map(function(e, idx) {
                                return (<SegmentRow key={e.id} effort={e} activity={activityInfo} isOwner={isSignedInAsOwner()}/>)
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


function SegmentRow({activity, effort, isOwner}) {

    function formatTime(seconds) {
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds - hours * 3600) / 60) 
        const sec = seconds - hours * 3600 - minutes * 60

        let minutesString = `${minutes}`
        if (minutes < 10) { minutesString = `0${minutesString}` }

        let secString = `${sec}`
        if (sec < 10) { secString = `0${secString}` }

        if (hours === 0) { return `${minutes}:${secString}` }
        else { return `${hours}:${minutesString}:${secString}` }
    }

    function metricSpeed() { return ((effort.distance / effort.elapsed_time) * 3.6).toFixed(1) + 'km/h' }
    function imperialSpeed() { return ((effort.distance / effort.elapsed_time) * 3.6 * 0.62137).toFixed(1) + 'mph' }
    console.log(effort.wind_speed)
    return (
        <div className="EffortRow">
            <div className='SegmentInfo'>
                <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                    { effort.starred === true ? <img style={{height: "15px", width: "15px", opacity: 0.8}} src={star}/> : <div style={{display: "none"}}/> }
                    <h3>{effort.name}</h3>
                </div>
                <a className='ViewOnStrava' href={effort === undefined ? "" : `https://strava.com/segments/${effort.segment_id}`}>View on Strava</a>
                </div>
                    <p className='Detail Time'><p className='DetailCaption'>Time<br/></p>{formatTime(effort.elapsed_time)}</p>
                    <p className='Detail Speed'><p className='DetailCaption'>Speed<br/></p>{activity.speed_hidden === true && !isOwner ? "-" : activity.preferred_units === 'meters' ? metricSpeed() : imperialSpeed()}</p>
                    <p className='Detail HR'><p className='DetailCaption'>HR<br/></p>{activity.heartrate_hidden === true && !isOwner ? "-" : effort.average_heartrate === null || effort.average_heartrate === undefined ? "-" : `${effort.average_heartrate.toFixed(0)}bpm`}</p>
                    <p className='Detail PWR'><p className='DetailCaption'>Power<br/></p>{activity.power_hidden === true && !isOwner ? "-" : effort.average_watts === null || effort.average_watts === undefined  ? "-" : `${effort.average_watts.toFixed(0)}w`}{effort.device_watts === true ? <img src={power}/> : ""}</p>
                    <ConditionsBar headwind={effort.headwind} crosswind={Math.abs(100 - effort.headwind - effort.tailwind)} tailwind={effort.tailwind} windspeed={activity.preferred_units === 'meters' ? `${(effort.wind_speed * 1.609344).toFixed(1)}km/h` : `${(effort.wind_speed).toFixed(1)}mph` }/>
                    {/* <p className='EffortConditions'> <b className='WindDetail Headwind'>{`${effort.headwind.toFixed(1)}`}%</b> | <b className='WindDetail Crosswind'>{`${(Math.abs(100 - effort.headwind - effort.tailwind)).toFixed(1)}`}%</b> | <b className='WindDetail Tailwind'>{`${effort.tailwind.toFixed(1)}`}%</b> @ <b>{effort.wind_speed.toFixed(1)}mph</b></p> */}
                <div>
            </div>
        </div>
    )
}

export function ConditionsBar({headwind, tailwind, crosswind, windspeed}) {
    return (
        <div className='EffortConditions' style={{paddingTop: "10px", width: "100%"}}>
            <div className="EffortConditionsBar">
                <p style={{color: "black" }}><b style={{color: "var(--windGreen)"}}>{tailwind.toFixed(1)}%</b> <b style={{opacity: 0.5, fontWeight: 500}}>|</b> <b style={{color: "var(--windYellow)"}}>{crosswind.toFixed(1)}%</b> <b style={{opacity: 0.5, fontWeight: 500}}>|</b> <b style={{color: "var(--windRed)"}}>{headwind.toFixed(1)}%</b></p>
                <div className="AnimateFlexGrow" style={{display: 'flex', flexGrow: 1, height: "20px", borderRadius: "3px", overflow: "hidden"}}>
                    <div style={{backgroundColor: "var(--windGreen)", width: `${tailwind}%`, height: "100%"}}/>
                    <div style={{backgroundColor: "var(--windYellow)", width: `${crosswind}%`, height: "100%"}}/>
                    <div style={{backgroundColor: "var(--windRed)", width: `${headwind}%`, height: "100%"}}/>
                </div>
            </div>

            <h3 style={{margin: 0, padding: 0, paddingTop: "5px", fontSize: "15px", fontWeight: 500}}>Wind Speed: <b>{windspeed}</b></h3>
        </div>
    )
}