import './Navbar.css'
import { StravaAPI } from '../../Helpers/StravaAPIHelper';
import { useEffect, useState } from 'react';
import strava from './../../Assets/connect-with-strava.svg'
import hamburgermenu from '../../Assets/hamburgermenu.png'

export default function Navbar({cookies, setCookie}) {

    function isNotLoggedIn() { return (cookies.user === undefined || cookies.user === null || Object.keys(cookies.user).length === 0 || cookies.user.athlete === null) }

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [navbarExpanded, setNavbarExpanded] = useState(false);

    function startAuth() {
        const currPage = window.location.href.toString().split(window.location.host)[1];
        if (currPage.length > 15 && currPage.slice(0, 13) === '/activity?id=') {
            StravaAPI.authenticate(false, `activity_${currPage.slice(13)}`)
        } else { StravaAPI.authenticate(false) }
    }

    useEffect(() => { if (!isNotLoggedIn() && cookies.user.secret === undefined) { setCookie('user', {}) } }, [])

    return (
        <div className='NavbarAndDropdown'>
            <div className="Navbar">
                <div className='NavbarLeft'>
                    <div className="NavBarBranding">
                        <img onClick={() => setNavbarExpanded(!navbarExpanded)} className='ExpandMenuButton' src={hamburgermenu}/>
                        <a href="/" className="navBarTitle"><b>WindMate</b></a>
                    </div>
                    <a href="/" className='NavBarButton LargeNavBarOnly'>Home</a>
                    <a onClick={() => { if (isNotLoggedIn()) {StravaAPI.authenticate(false, 'goto_segments')} else {window.location.href='/segments'} }} className='NavBarButton LargeNavBarOnly'>Segments</a>
                    <a onClick={() => { if (isNotLoggedIn()) {StravaAPI.authenticate(false, 'goto_routes')} else {window.location.href='/routes'} }} className='NavBarButton LargeNavBarOnly'>Routes</a>
                    <a href="/about" className='NavBarButton LargeNavBarOnly'>About</a>
                    <a href="/subscribe" className='NavBarButton LargeNavBarOnly'>Subscribe</a>
                    <a href="/plugin" className='NavBarButton LargeNavBarOnly'>Plug-in</a>
                </div>
                
                <div className='NavBarRight'>
                    {
                        isNotLoggedIn() ? 
                        <img src={strava} className='SignInButton' onClick={startAuth}/> :
                        <div onMouseEnter={() => { setDropdownVisible(true) }} onMouseLeave={() => { setDropdownVisible(false) }}>
                            <a style={{zIndex: 100}} className='NavBarButton'>{cookies.user.athlete.firstname} {cookies.user.athlete.lastname}</a>
                            <div onClick={() => { if (dropdownVisible) { setCookie('user', {}) } }} style={{zIndex: 10}} className={`SignOutDropdown ${dropdownVisible ? "DropdownVisible" : ""}`}>Sign out</div>
                        </div>
                    }
                </div>
            </div>

            <div className={`NavbarDropdown ${navbarExpanded ? 'DropdownExpanded' : ''}`}>
                <a href="/" className='DropdownButton'>Home</a>
                <a href="/segments" className='DropdownButton'>Segments</a>
                <a href="/routes" className='DropdownButton'>Routes</a>
                <a href="/about" className='DropdownButton'>About</a>
                <a href="/subscribe" className='DropdownButton'>Subscribe</a>
                <a href="/plugin" className='DropdownButton'>Plug-in</a>
            </div>
        </div>
    )
}
