import './SignInPrompt.css'
import logo from '../Assets/HomePage_Logo.png'
import arrow from '../Assets/arrow.png'

export function SubscribeSuccess() {
    return (
        <div className='SignInPromptContainer'>
            <img src={logo} style={{height: "200px"}}/>
            <h2>You're all set!</h2>
            <p>Thanks for subscribing! The full set of WindMate+ features is waiting for you.</p>
            <div className="ButtonDiv" style={{backgroundColor: "var(--color-1)", color: "white", padding: "10px", borderRadius: "10px", display: "flex", alignItems: "center", gap: "8px"}} onClick={() => { window.location.href = '/segments' }}>
                <p>Let's go</p>
                <img src={arrow}/>
            </div>
        </div>
    )
}