import { useEffect, useState } from "react"
import { StravaAPI, refreshTokensIfNeeded } from "../Helpers/StravaAPIHelper";
import './Home/Segments.css'
import { Analytics } from "../Helpers/Analytics";
import MagnifyingGlass from '../Assets/magnifyingglass.png'
import { SignInPrompt } from "../Components/SignInPrompt";
import { LoadingIndicator } from "../Components/LoadingIndicator";
import { formatCoordinates } from "../Helpers/MapHelper";
import { useMap, MapContainer, TileLayer, Polyline } from "react-leaflet";
import PolylineDecoder from "@mapbox/polyline"



export function RoutesPage({cookies, setCookie}) {

    const [allRoutes, setAllRoutes] = useState(null)
    const [filteredRoutes, setFilteredRoutes] = useState(null);
    const [preferredUnits, setPreferredUnits] = useState("imperial");
    const [searchInput, setSearchInput] = useState("");

    useEffect(() => {
        Analytics.logPageView("Routes Page", "/segments", cookies, setCookie)
        if (isNotLoggedIn()) {  return; }
        loadRoutes();
    }, [])

    async function loadRoutes() {
        const accessToken = await refreshTokensIfNeeded(cookies, setCookie);
        getUserPreferences(accessToken)
        const loadedRoutes = await StravaAPI.getRoutes(cookies.user.athlete.id, accessToken)
        console.log(loadedRoutes)
        setAllRoutes(loadedRoutes)
        setFilteredRoutes(loadedRoutes)
    }

    function isNotLoggedIn() { return (cookies.user === undefined || cookies.user === null || Object.keys(cookies.user).length === 0 || cookies.user.athlete === null) }

    const handleChange = (e) => {
        e.preventDefault();

        const searchString = e.target.value;

        if (searchString === "") { setFilteredRoutes(allRoutes) }

        setFilteredRoutes(
            allRoutes.filter(s => {
                const search = searchString.toLowerCase().trim();
                const props = ['name'];
                return props.some(prop => (s[prop] || '').toLowerCase().includes(search));
            })
        )

        setSearchInput(e.target.value);
    };

    async function getUserPreferences(accessToken) {
        if (cookies.user.allowedScopes === undefined || cookies.user.allowedScopes.includes('profile:read_all')) {
            const userInfo = await StravaAPI.getUserInfo(accessToken)
            const preference = userInfo.measurement_preference
            setPreferredUnits(preference)
        } else {
            const preference = (['US', 'us', 'United States', 'USA', 'U.S.'].includes(cookies.user.athlete.country)) ? 'imperial' : 'meters'
            setPreferredUnits(preference)
        }
    }

    if (isNotLoggedIn()) {
        return (<SignInPrompt/>)
    } else if (filteredRoutes === null) {
        return (
         <div style={{height: "70vh"}}>
            <LoadingIndicator/>
            <canvas id="forecast" hidden></canvas>
        </div>)
    } else {
        return (
            <div className="SegmentsContainer">
                <h1>Your Favorited Segments</h1>
                <div className='SubtitleContainer' style={{display: "flex", justifyContent: "space-between"}}>
                    <p style={{opacity: 0.6, fontSize: "14px", marginTop: "-10px", marginBottom: "20px"}} onClick={() => { window.location.href = 'https://www.strava.com/athlete/routes' }}><u>View on Strava</u></p>
                </div>
                
                <div className="TopBar">
                    <div className="SearchBar">
                        <img src={MagnifyingGlass}/>
                        <input
                        type="text"
                        placeholder="Search"
                        onChange={handleChange}
                        value={searchInput}/>
                    </div>
                </div>
                
                <div className="SegmentsGrid">
                    { filteredRoutes.map(r => {return (<RouteCard key={r.id} route={r} preferredUnits={preferredUnits}/>)}) }
                    <div/>
                    <div/>
                </div>

                {
                    (filteredRoutes.length === 0) ? <div style={{opacity: 0.8, textAlign: "center", marginTop: "80px", marginBottom: "100px"}}>{(allRoutes.length === 0) ? "You have not created any Strava routes yet!" : "No results match your search! Typo?"}</div> : <div/>
                }
            </div>
        )
    }
}


function RouteCard({route, preferredUnits}) {

    function goToSegment() {
        window.location.href = `/route?id=${route.id_str}`
    }

    function decodedPoyline() {
        const encoded = route.map.summary_polyline
        return PolylineDecoder.decode(encoded)
    }

    function MapResizer({bounds}) {
        const map = useMap()

        useEffect(() => {
            if (bounds === null) { return; }
            map.fitBounds(bounds)
        }, [bounds])

        return (<div/>)
    }

    function metricDistance() { return (route.distance / 1000).toFixed(1) + 'km' }
    function imperialDistance() { return ((route.distance / 1000) * 0.62137).toFixed(1) + 'mi' }
    function creationDate() { return new Date(route.created_at).toLocaleDateString(undefined, {year: 'numeric', month: 'long', day: 'numeric',}) }

    return (
        <div className="SegmentCard" onClick={goToSegment}>
                
            <div className='SegmentInfoHeader'>
                <h3>{route.name}</h3>
                <p className="SegmentLocation">Created on {creationDate()} by {route.athlete.firstname} {route.athlete.lastname}</p>
            </div>

            {
                <div className="SegmentCardBottom">
                    <MapContainer className="SmallSegmentMap" center={[0, 0]} zoom={2} scrollWheelZoom={false} zoomControl={false} zoomSnap={0} attributionControl={false} dragging={false}>
                        <TileLayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"/>
                        <Polyline positions={decodedPoyline()} color="var(--navyblue)"/>
                        <MapResizer bounds={formatCoordinates(decodedPoyline()).bounds}/>
                    </MapContainer>

                    <div className='BottomRight'>
                        <p>{preferredUnits === 'meters' ? metricDistance() : imperialDistance()}</p>
                    </div>
                </div>
            }
        </div>
    )
}