import './DurationPicker.css'

export default function DurationPicker({selected, setDuration}) {

    return (
        <div className="DurationPicker">
            <div className={selected === 12 ? "SelectedDuration" : "NotSelectedDuration"} onClick={() => setDuration(12)}>12h</div>
            <div className={selected === 24 ? "SelectedDuration" : "NotSelectedDuration"} onClick={() => setDuration(24)}>24h</div>
            <div className={selected === 48 ? "SelectedDuration" : "NotSelectedDuration"} onClick={() => setDuration(48)}>2d</div>
            {/* <div className={selected === 168 ? "SelectedDuration" : "NotSelectedDuration"} onClick={() => setDuration(168)}>7d</div> */}
        </div>
    )
}