import { useState, useEffect } from "react";
import { SegmentCard } from "../../SegmentCard";
import { StravaAPI, refreshTokensIfNeeded } from "../../Helpers/StravaAPIHelper";
import { LoadingIndicator } from "../../Components/LoadingIndicator";
import './Segments.css'
import MagnifyingGlass from './../../Assets/magnifyingglass.png'
import { SignInPrompt } from "../../Components/SignInPrompt";
import 'leaflet/dist/leaflet.css';
import { Analytics } from "../../Helpers/Analytics";

export function Segments({cookies, setCookie, allSegments, setAllSegments}) {

    const [segments, setSegments] = useState(null);
    const [accessToken, setAccessToken] = useState(null)
    const [preferredUnits, setPreferredUnits] = useState("imperial")

    useEffect(() => {
        Analytics.logPageView("Segments Page", "/segments", cookies, setCookie)
        if (isNotLoggedIn()) { return; }
        
        loadSegments();
    }, [])

    async function loadSegments() {
        const token = await refreshTokensIfNeeded(cookies, setCookie)
        setAccessToken(token)
        getUserPreferences(token);
        const s = await StravaAPI.getStarredSegments(token)
        setAllSegments(s)
        setSegments(s)
    }

    function isNotLoggedIn() { return (cookies.user === undefined || cookies.user === null || Object.keys(cookies.user).length === 0 || cookies.user.athlete === null) }
    const [searchInput, setSearchInput] = useState("");

    const handleChange = (e) => {
        e.preventDefault();

        const searchString = e.target.value;

        if (searchString === "") { setSegments(allSegments) }

        setSegments(
            allSegments.filter(s => {
                const search = searchString.toLowerCase().trim();
                const props = ['name', 'city', 'country', 'state'];
                return props.some(prop => (s[prop] || '').toLowerCase().includes(search));
            })
        )

        setSearchInput(e.target.value);

    };

    async function getUserPreferences(accessToken) {
        if (cookies.user.allowedScopes === undefined || cookies.user.allowedScopes.includes('profile:read_all')) {
            const userInfo = await StravaAPI.getUserInfo(accessToken)
            const preference = userInfo.measurement_preference
            setPreferredUnits(preference)
        } else {
            const preference = (['US', 'us', 'United States', 'USA', 'U.S.'].includes(cookies.user.athlete.country)) ? 'imperial' : 'meters'
            setPreferredUnits(preference)
        }
    }

    if (isNotLoggedIn()) {
        return (<SignInPrompt/>)
    } else if (segments === null) {
        return (
         <div style={{height: "70vh"}}>
            <LoadingIndicator/>
            <canvas id="forecast" hidden></canvas>
        </div>)
    } else {
        return (
            <div className="SegmentsContainer">
                <h1>Your Favorited Segments</h1>
                <div className='SubtitleContainer' style={{display: "flex", justifyContent: "space-between"}}>
                    <p style={{opacity: 0.6, fontSize: "14px", marginTop: "-10px", marginBottom: "20px"}} onClick={() => { window.location.href = 'https://www.strava.com/athlete/segments/starred' }}><u>View on Strava</u></p>
                </div>
                
                <div className="TopBar">
                    <div className="SearchBar">
                        <img src={MagnifyingGlass}/>
                        <input
                        type="text"
                        placeholder="Search"
                        onChange={handleChange}
                        value={searchInput}/>
                    </div>
                </div>
                
                <div className="SegmentsGrid">
                    { segments.map(s => {return (<SegmentCard key={s.id} segment={s} accessToken={accessToken} preferredUnits={preferredUnits}/>)}) }
                    <div/>
                    <div/>
                </div>

                {
                    (segments.length === 0) ? <div style={{opacity: 0.8, textAlign: "center", marginTop: "80px", marginBottom: "100px"}}>{(allSegments.length === 0) ? "You have not starred any segments in Strava yet!" : "No results match your search! Typo?"}</div> : <div/>
                }
            </div>
        )
    }
    

}
