import { useEffect, useState } from "react";
import './Segment.css'
import { drawForecast, drawTempChart, drawWindChart, drawPressureChart, formatDate } from "../../Helpers/ForecastChartHelper";
import { EffortRow } from "../../EffortRow";
import DurationPicker from "../../Components/DurationPicker/DurationPicker";
import { refreshTokensIfNeeded } from "../../Helpers/StravaAPIHelper";
import { LoadingIndicator, SmallLoadingIndicator } from "../../Components/LoadingIndicator";
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Polyline, useMap, ZoomControl, Circle } from 'react-leaflet'
import { Firestore, saveSegmentCoordsToDB } from "../../firebase";
import ruler from '../../Assets/ruler.png'
import mountain from '../../Assets/mountain.png'
import grade from '../../Assets/grade.png'
import lock from '../../Assets/lock.png'
import windarrow from '../../Assets/windarrow.png'
import { Fragment } from "react";
import { StravaAPI } from "../../Helpers/StravaAPIHelper";
import { SignInPrompt } from "../../Components/SignInPrompt";
import { Analytics } from "../../Helpers/Analytics";
import { WeatherDataHelper } from "../../Helpers/WeatherDataHelper";


export function Segment({cookies, setCookie}) {

    const [accessToken, setAccessToken] = useState(null)

    const [preferredUnits, setPreferredUnits] = useState('imperial')

    const [selectedTimeIndex, setSelectedTimeIndex] = useState(null)
    
    // Segment info (name, distance, location, efforts...)
    const [segment, setSegment] = useState(null)

    // Stores wether user is subscribed/not to decide which features to give access to
    const [subscribed, setSubscribed] = useState(false);

    // References to the chart objects, used to draw them
    const [chart, setChart] = useState(null)
    const [tempChart, setTempChart] = useState(null)
    const [windChart, setWindChart] = useState(null)
    const [pressureChart, setPressureChart] = useState(null)

    // Weather data {'Hours': [], 'HeadWind': [], 'CrossWind': [], 'TailWind': [], 'Pressure': [], 'WindSpeed': [], 'WindDirection': [], 'Temperature': []}
    const [weatherData, setWeatherData] = useState(null)

    // Selecte forecast duration
    const [hours, setHours] = useState(12)

    // Segment coordinates
    const [segmentMap, setSegmentMap] = useState(null)

    // Segment efforts
    const [efforts, setEfforts] = useState(null)
    const [pr, setPr] = useState(null)

    // Map bounds
    const [bounds, setBounds] = useState(null)

    // Coordinate pairs for segment start and end
    const [segmentStart, setSegmentStart] = useState([0,0])
    const [segmentEnd, setSegmentEnd] = useState([0,0])

    // Size of circles to mark end and start
    const [circleRadius, setCircleRadius] = useState(25)

    useEffect(() => { Analytics.logPageView("Segment Page", "/segment", cookies, setCookie); ; getData() }, [])
    useEffect(() => { renderCharts() }, [weatherData, hours])
    useEffect(() => { rerenderColors() }, [cookies.highContrast])
    useEffect(() => { getWindData(); renderMap() }, [segmentMap])

    function rerenderColors() {
        if (weatherData === null || weatherData === undefined) { return; }
        setTimeout(function() {
            drawForecast(weatherData, chart, setChart, hours, setSelectedTimeIndex)
        }, 100);
    }

    function isNotLoggedIn() { return (cookies.user === undefined || cookies.user === null || Object.keys(cookies.user).length === 0 || cookies.user.athlete === null) }

    // Runs when view loads
    async function getData() {
        if (isNotLoggedIn()) { return }
        // Get a valid access token 
        const aToken = await refreshTokensIfNeeded(cookies, setCookie);
        setAccessToken(aToken);

        // Load from the database wether user is subscribed or not
        setSubscribed(await Firestore.getSubscriptionStatus(cookies.user.athlete.id))

        // Read parameters from URL
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id')

        // These are all done in parallel but return at different times
        getSegmentInfo(aToken, id);
        getSegmentMap(aToken, id);
        getSegmentEfforts(aToken, id);
    }

    async function getSegmentInfo(accessToken, segmentID) {
        const s = await StravaAPI.getSegment(segmentID, accessToken)
        setSegment(s);
        getUserPreferences(accessToken)
    }

    // If the map does not exist in the database yet, load it from the API, save it to the database and return it
    async function loadMapFromAPI(accessToken, segmentID) {
        const coords = await StravaAPI.getSegmentCoordinates(segmentID, accessToken)
        saveSegmentCoordsToDB(segmentID, coords)
        return coords
    }

    // Get segment coords, first trying from database
    async function getSegmentMap(accessToken, segmentID) {
        let m = await Firestore.getSegmentCoordinates(segmentID);
        
        if (m === null) { m = await loadMapFromAPI(segmentID, accessToken); } 
        else { m = m.map(c => [parseFloat(c[0]), parseFloat(c[1])])}

        setSegmentMap(m)
    }

    // Get the list of efforts for the segment
    async function getSegmentEfforts(accessToken, segmentID) {
        const efforts = await StravaAPI.getSegmentEfforts(segmentID, accessToken);
        if (efforts.length > 0) { setPr(efforts.sort((a, b) => a.elapsed_time - b.elapsed_time)[0]) }
        else { setPr("no_efforts") }
        setEfforts(efforts.sort((a, b) => new Date(b.start_date_local) - new Date(a.start_date_local)))
    }


    async function getWindData() {
        if (segmentMap === null) { return }

        const subscribed = await Firestore.getSubscriptionStatus(cookies.user.athlete.id)
        const weatherData = await WeatherDataHelper.getWeatherForecast(segmentMap, subscribed ? 48 : 12)
    
        setWeatherData(weatherData)
    }

    // This runs when the map is loaded, and renders the polyline + resizes and centers the map
    function renderMap() {
        if (segmentMap === null) { return; }
        let [maxX, maxY, minX, minY] = [-10000, -10000, 100000, 100000]

        for (const coord of segmentMap) {
            maxX = Math.max(maxX, coord[0]);
            minX = Math.min(minX, coord[0]);
            maxY = Math.max(maxY, coord[1]);
            minY = Math.min(minY, coord[1]);
        }

        const maxSizeDiff = Math.max(maxX - minX, maxY - minY)
        const padding = maxSizeDiff * 0.1;
        setBounds([[minX - padding, minY - padding], [maxX + padding, maxY + padding]])
        setSegmentEnd(segmentMap[segmentMap.length - 1])
        setSegmentStart(segmentMap[0])
        setCircleRadius(maxSizeDiff * 1200)
    }

    // Runs when weather data is loaded
    function renderCharts() {
        if (weatherData === null || weatherData === undefined) { return; }
        drawForecast(weatherData, chart, setChart, hours, setSelectedTimeIndex)
        drawTempChart(weatherData, tempChart, setTempChart, hours, preferredUnits);
        drawWindChart(weatherData, windChart, setWindChart, hours, preferredUnits);
        drawPressureChart(weatherData, pressureChart, setPressureChart, hours);
    }

    // Invisible view used to resize the map using black magic
    function MapResizer({bounds}) {
        const map = useMap()

        useEffect(() => {
            if (bounds === null) { return; }
            map.fitBounds(bounds)
        }, [bounds])

        return (<div/>)
    }

    // View that is displayed to indicate that user must be subscribed to unlock
    function Locked({additional}) {
        return (<div style={{width: "100%", height: "100%", color: "black", alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <img style={{height: "40px"}} src={lock}/>
            <p style={{marginBottom: 0}}>Get <b>WindMate+</b> to unlock {additional}</p>
        </div>)
    }

    // Returns an array of polylines of different colors corresponding to the different wind types on the segment, runs when the weather data is loaded.
    function ColoredSegmentPolyline() {
        let progress = 0;

        const style = getComputedStyle(document.body)
        const green = style.getPropertyValue('--windGreen')
        const yellow = style.getPropertyValue('--windYellow')
        const red = style.getPropertyValue('--windRed')

        const timestamps = Object.keys(weatherData)
        timestamps.sort()
        const mostRecentTimestamp = timestamps[selectedTimeIndex === null ? 0 : selectedTimeIndex]
        const mostRecentWeatherData = weatherData[mostRecentTimestamp]

        return (
            <div>
                {Object.values(mostRecentWeatherData.sections).map((data, i) => (
                    <Fragment key={progress}>
                        <Polyline positions={segmentMap.slice(Math.max(0, progress - 1), progress + data.length + ((i === (Object.values(mostRecentWeatherData.sections).length - 1)) ? 1 : 0))} lineJoin="round" weight={5} color={data.windType === "headwind" ? red : (data.windType === "crosswind" ? yellow : green)} />
                        { progress = progress + data.length }
                    </Fragment>
                ))}
            </div>
        )
    }

    async function getUserPreferences(accessToken) {
        if (cookies.user.allowedScopes === undefined || cookies.user.allowedScopes.includes('profile:read_all')) {
            const userInfo = await StravaAPI.getUserInfo(accessToken)
            const preference = userInfo.measurement_preference
            setPreferredUnits(preference)
        } else {
            const preference = (['US', 'us', 'United States', 'USA', 'U.S.'].includes(cookies.user.athlete.country)) ? 'imperial' : 'meters'
            setPreferredUnits(preference)
        }
    }

    function windDirection() {
        if (weatherData === null) { return null };

        const timestamps = Object.keys(weatherData)
        timestamps.sort()
        const mostRecentTimestamp = timestamps[selectedTimeIndex === null ? 0 : selectedTimeIndex]
        const mostRecentWeatherData = weatherData[mostRecentTimestamp]
        return mostRecentWeatherData.windDirection
    }

    function metricDistance(d) { return (d / 1000).toFixed(1) + 'km' }
    function imperialDistance(d) { return ((d / 1000) * 0.62137).toFixed(1) + 'mi' }
    function metricElevation(e) { return e.toFixed(0) + 'm' }
    function imperialElevation(e) { return (e * 3.28084).toFixed(0) + 'ft' }


    if (isNotLoggedIn()) {
        return (<SignInPrompt/>)
    } else if (segment === null) { // Before anything is loaded, display a loading indicator
        return (
         <div style={{height: "70vh"}}>
            <LoadingIndicator/>
            <canvas id="forecast" hidden></canvas>
        </div>)
    } else { // Otherwise, display the content
        return (
            <div>
                <div className="SegmentContent">
            
                <div className="SegmentSummary">
                    <h1 style={{color: "white"}}>{segment.name}</h1>

                    <div className="SegmentSummaryDistElev">
                        <img src={ruler}/>
                        <p>{preferredUnits === 'meters' ? metricDistance(segment.distance) : imperialDistance(segment.distance)}</p>

                        <img src={mountain}/>
                        <p>{preferredUnits === 'meters' ? metricElevation(segment.total_elevation_gain) : imperialElevation(segment.total_elevation_gain)}</p>

                        <img src={grade}/>
                        <p>{segment.average_grade.toFixed(1)}%</p>
                    </div>
                </div>
                
                <p className="SegmentDetails">{[segment.city, segment.state, segment.country].filter(s => (s !== "" && s !== null)).join(', ')} • <a href={`https://strava.com/segments/${segment.id}`}>View on Strava</a></p>

                <div className="UpperRow">
                    <div>
                        <h2 className="CardTitle">{selectedTimeIndex === null || selectedTimeIndex === 0 ?'Live Conditions' : `Conditions at ${formatDate(Object.keys(weatherData)[selectedTimeIndex])}`}</h2>
                        <div className="LiveCard">
                            {
                                (segmentMap === null) ? <div>Loading...</div> :
                                <div style={{display: "grid", gridRow: 1, gridColumn: 1}}>
                                    {
                                        // Don't display the wind arrow before weather data is displayed
                                        (weatherData === null) ? <div style={{display: "none"}}/> : 
                                        <img className='WindArrow' src={windarrow} style={{ transform: `rotate(${180 + windDirection()}deg)` }} />

                                    }

                                    <MapContainer className="LiveMap" center={[0,0]} zoom={12} scrollWheelZoom={false} zoomControl={false} zoomSnap={0} attributionControl={false} dragging={(window.screen.width > 1000)}>
                                        <TileLayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"/>
                                        <Circle center={segmentStart} pathOptions={{ fillColor: 'green', color: 'green' }} fillOpacity="1" radius={circleRadius} />
                                        <Circle center={segmentEnd} pathOptions={{ fillColor: 'red', color: 'red' }} fillOpacity="1" radius={circleRadius} />
                                        {
                                            // While waiting for the weather data to load, just display the segment polyline in gray
                                            (weatherData === null) ? 
                                            <Polyline positions={segmentMap} color="rgba(0,0,0,0.7)" /> :
                                            <ColoredSegmentPolyline/>
                                        }
                                        <MapResizer bounds={bounds}/>
                                        <ZoomControl position="topright" />
                                    </MapContainer>
                                </div>
                                
                            }
                        </div>
                    </div>
                    
                    <div>
                        <div className="ForecastCardHeader" style={{display: "flex", alignItems: "baseline"}}>
                            <h2>Forecasted Conditions</h2>
                            <DurationPicker selected={hours} setDuration={setHours}/>
                        </div>
                        
                        <div className="ForecastCard">
                            { (weatherData === null) ? <SmallLoadingIndicator/> : (subscribed === true || hours === 12) ? 
                            <canvas onMouseLeave={() => { setSelectedTimeIndex(null) }} className='ConditionsChart' id="forecast"/> : <Locked additional=" forecasts up to 2 days ahead"/> }
                        </div>
                    </div>
                </div>

                <div className="SmallChartGrid" style={{display: "grid", gap: "20px"}}>
                    <div className="DottedBackground" style={{display: "flex", flexDirection: "column", color: "#3C5B6F",borderRadius: '15px'}}>
                        <h3 style={{marginTop:"5px", fontSize: '20px', textAlign: 'center'}}>Wind Speed ({ preferredUnits === 'meters' ? "kph" : "mph" })</h3>
                        <div style={{width: "100%", borderRadius: "15px", height: "200px"}}>
                            {subscribed || hours === 12 ? weatherData === null ? <SmallLoadingIndicator/> : <canvas style={{padding: "10px"}} id="windspeed"/> : <Locked/>}
                        </div>
                    </div>

                    <div className="DottedBackground" style={{display: "flex", flexDirection: "column", color: "#3C5B6F", borderRadius: '15px'}}>
                        <h3 style={{marginTop:"5px", fontSize: '20px', textAlign: 'center'}}>Temperature ({ preferredUnits === 'meters' ? "°C" : "°F" })</h3>
                        <div style={{width: "100%", borderRadius: "15px", height: "200px"}}>
                            {subscribed || hours === 12 ? weatherData === null ? <SmallLoadingIndicator/> :  <canvas style={{padding: "10px"}} id="temperature"/> : <Locked/>}
                        </div>
                    </div>

                    <div className="DottedBackground"  style={{display: "flex", flexDirection: "column", color: "#3C5B6F", borderRadius: '15px'}}>
                        <h3 style={{marginTop:"5px", fontSize: '20px', textAlign: 'center'}}>Air Pressure (hPa)</h3>
                        <div style={{width: "100%", borderRadius: "15px", height: "200px"}}>
                            {subscribed || hours === 12 ? weatherData === null ? <SmallLoadingIndicator/> :  <canvas style={{padding: "10px"}} id="airpressure"/> : <Locked/>}
                        </div>
                    </div>
                </div>

            </div>

            <div className='EffortContent'>

                <div className="EffortList" style={{marginBottom: "250px"}}>
                    <h2>&#127942; Personal Record</h2>
                    <div className="Legend DesktopOnly">
                        <p><b>Date</b></p>
                        <p><b>Time</b></p>
                        <p><b>Speed</b></p>
                        <p><b>Heart Rate</b></p>
                        <p><b>Power</b></p>
                        <p><b>Wind Conditions</b></p>
                    </div>

                    {
                        (pr === null) ? <div>Loading...</div>: 
                            (pr === "no_efforts") ?  <div className="NoSegmentEfforts">You have not completed this segment yet.</div> : 
                            <EffortRow effort={pr} subscribed={subscribed} accessToken={accessToken} preferredUnits={preferredUnits}/>
                    }
                </div>

                <div className="EffortList">
                    <h2>Recent Efforts</h2>
                    <div className="Legend DesktopOnly">
                        <p><b>Date</b></p>
                        <p><b>Time</b></p>
                        <p><b>Speed</b></p>
                        <p><b>Heart Rate</b></p>
                        <p><b>Power</b></p>
                        <p><b>Wind Conditions</b></p>
                    </div>

                    {
                        (efforts === null) ? <div>Loading...</div>: 
                            (efforts.length === 0) ?  <div className="NoSegmentEfforts">You have not completed this segment yet.</div> : 
                            efforts.map(function(d, idx){
                                return (<EffortRow key={d.id} effort={d} subscribed={subscribed} accessToken={accessToken} preferredUnits={preferredUnits}/>)
                            })
                    }
                    </div>
                </div>
            </div>
        )
    }
  }
