import axios from "axios";
import { Analytics } from "./Analytics";

export class Stripe {

    static async startSubscriptionSession(athleteID) {
        try {
            const response = await axios.get('https://us-central1-mygame-c2230.cloudfunctions.net/create_stripe_session', { params: { athlete_id: athleteID } })
            const sessionUrl = response.data
            window.location.href = sessionUrl;
        } catch (err) { 
            Analytics.logStripeError("subscription_session_creation_failed")
            console.log(err)
        }
    }

    static async startSubscriptionManagementSession(athleteID) {
        try {
            const response = await axios.get('https://us-central1-mygame-c2230.cloudfunctions.net/manage_subscription', { params: { athlete_id: athleteID } })
            const sessionUrl = response.data
            window.location.href = sessionUrl;
        } catch (err) { 
            Analytics.logStripeError("manage_subscription_session_creation_failed")
            console.log(err)
        }
    }

}