import './Footer.css'
import apilogo from'../../Assets/strava-api-logo.svg'
import Switch from '../../Components/Switch/Switch';
import { useEffect, useState } from 'react';

function Footer({cookies, setCookie}) {

    const [highContrast, setHighContrast] = useState(cookies.highContrast === true)

    useEffect(() => { setCookie('highContrast', highContrast) }, [highContrast])
    
    function toggleHighContrast() {
        setHighContrast(!highContrast)
    }

    return (
        <div className="Footer">
            <div className='FooterGrid'>
                <div className='FooterCol'>
                    <a href='/'>Home</a>
                    <a href='segments'>Segments</a>
                    {/* <a href='/routes'>Routes</a> */}
                    <a href='/about'>About</a>
                </div>

                <div/>

                <div className='FooterCol'>
                    <a href='/subscribe'>Subscribe</a>
                    <a href='/plugin'>Plug-in</a>
                    <a href='mailto:contact@windmate.io'>Contact</a>
                </div>

                <div className='FooterCol'>
                    <img className="APILogo" src={apilogo}/>
                    <div>
                        <p style={{opacity: 0.8, fontSize: "13px", paddingBottom: 0, marginBottom: "6px"}}>High Contrast mode</p>
                        <Switch isOn={highContrast}
                                onColor="#EF476F"
                                handleToggle={toggleHighContrast}/>
                    </div>
                </div>
            </div>

            <p>© Marius Genton & Max Eisenberg, 2024</p>
        </div>
    )
}


export default Footer;